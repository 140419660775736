import { Typography } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';

const calculateTimeRemaining = () => {
	const weddingDate = new Date('2023-09-23T16:00:00.000-06:00');
	const now = new Date();
	return intervalToDuration({
		start: now,
		end: weddingDate,
	});
};

const CountdownTimer = () => {
	const [timeRemaining, setTimeRemaining] = useState(
		calculateTimeRemaining(),
	);
	const timeArray: string[] = [];

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeRemaining(calculateTimeRemaining());
		}, 1000);

		return () => clearTimeout(timer);
	});

	Object.entries(timeRemaining).forEach(([duration, value]) => {
		if (!value) return;
		timeArray.push(
			`${value}\xa0${value === 1 ? duration.slice(0, -1) : duration}`,
		);
	});

	return (
		<>
			<Typography
				variant="subtitle1"
				align="center"
				color="text.primary"
				paragraph
			>
				{`${timeArray.join(', ')} since our wedding!`}
			</Typography>
			<Typography
				variant="caption"
				align="center"
				color="text.secondary"
				paragraph
			>
				Saturday, September 23, 2023 at 4:00 p.m. Mountain Time
			</Typography>
		</>
	);
};

export default CountdownTimer;
