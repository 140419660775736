import {
	Card,
	CardContent,
	Container,
	Grid,
	Stack,
	Typography,
} from '@mui/material';
import React from 'react';

import PhotoCard from './PhotoCard';
import PhotoModal from './PhotoModal';
import photos from '../data/photos';

// TODO sort photos by date
const Photos = () => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Grid container spacing={4}>
			{photos.map(({ name, date, height, width, metadata }) => (
				<Grid item key={name} xs={12} sm={6} md={4}>
					<Card
						sx={{
							// height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
						raised
					>
						<PhotoCard name={name} height={height} width={width} />
						<CardContent sx={{ flexGrow: 1 }}>
							<Stack direction="column">
								<Typography gutterBottom variant="caption">
									<>
										<b>Date:</b> {date}
									</>
								</Typography>
								{metadata.Make && metadata.Model && (
									<Typography gutterBottom variant="caption">
										<>
											<b>Taken with:</b> {metadata.Make}{' '}
											{metadata.Model}
										</>
									</Typography>
								)}
							</Stack>
						</CardContent>
						<PhotoModal name={name} height={height} width={width} />
					</Card>
				</Grid>
			))}
		</Grid>
	</Container>
);

export default Photos;
