import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
	palette: {
		mode: 'light',
		primary: {
			main: '#e3b23c',
		},
		secondary: {
			main: '#c75000',
		},
		success: {
			main: '#4e6151',
		},
		info: {
			main: '#93b7be',
		},
		error: {
			main: '#c75000',
		},
	},
	typography: {
		fontFamily: ['Playfair Display', 'serif'].join(','),
	},
};

export default themeOptions;
