import { Typography } from '@mui/material';

type LodgingInfo = {
	name: string;
	address: string;
	phoneNumber: string;
	imagePath: string;
	link: string;
	rating: number;
	review: (string | JSX.Element)[];
	insideScoop?: string[];
};

export type LodgingInfoArray = LodgingInfo[];

const lodgingInfo: LodgingInfoArray = [
	{
		name: 'The Firebrand Hotel',
		address: '650 East 3rd Street, Whitefish, MT  59937',
		phoneNumber: '(406) 863-1900',
		imagePath: '/assets/accommodation-photos/firebrand.webp',
		link: 'https://firebrandhotel.com/',
		rating: 4,
		review: [
			'The Firebrand Hotel is the sister hotel to The Lodge at Whitefish Lake. These two hotels are the most upscale accommodations in the area. The Firebrand Hotel was built in 2016 with a premier downtown Whitefish location, walking distance to shops and restaurants. The Friday evening Welcome Party will be in downtown Whitefish. The hotel offers complimentary shuttle service to and from the airport and the Lodge at Whitefish Lake. ',
			'We have a room block for Friday, September 22 and Saturday, September 23. King and double queen rooms are available for $409/night (before taxes and fees). The group rate is also available for three nights before and after our room block dates. Cancellation policy is 14 days in advance and the hotel requires payment of one night’s stay at the time of booking.  To get our group rate, call 406-863-4055 or 844-863-1900 and ask for the McKenzie-Sullivan wedding room block. Or book on-line with the following log-in: ',
			<>
				<Typography display="inline" fontWeight="bold">
					Group ID:&nbsp;
				</Typography>
				<Typography display="inline">MCKESULL</Typography>
			</>,
			<>
				<Typography display="inline" fontWeight="bold">
					Group password:&nbsp;
				</Typography>
				<Typography display="inline">WEDDING2023</Typography>
			</>,
			'The online reservation will only work for Friday and Saturday night so if you are booking additional dates, please call to get the best rate. The room block will expire on July 24, 2023 so please make your reservations before then.',
		],
		insideScoop: [
			'The location of this hotel can’t be beat – it’s the only place to stay in Whitefish where you can walk right out the door and enjoy all the great amenities of downtown. There is a wonderful rooftop where you can hang out and enjoy the view and I also love the modern vibe of the lobby that has a restaurant and bar that offers breakfast, brunch, dinner and cocktails.  Downside is that the rooms can be noisy – can hear street noise, other guests and nighttime trains – so you might want to bring a sound machine! ',
		],
	},
	{
		name: 'Good Medicine Lodge',
		address: '537 Wisconsin Ave, Whitefish, MT 59937',
		phoneNumber: '(406) 862-5488',
		imagePath: '/assets/accommodation-photos/good-medicine-lodge.webp',
		link: 'https://goodmedicinelodge.com/',
		rating: 5,
		review: [
			"The Good Medicine Lodge is conveniently located near Whitefish Lake, just a five-minute drive to downtown Whitefish. It's a lovely rustic bed-and-breakfast style lodge with unique rooms and suites. There are a variety of room sizes available: most accommodate two people but the largest can accommodate four. The lodge has a cozy indoor common space with a fireplace and books and also offers a communal backyard with a fire pit and hot tub. The vision behind the lodge is to be a base camp for adventurers. They provide guests with a hearty and delicious breakfast each morning, highlighted by coffee that is roasted in-house. They also pride themselves on sourcing all of their furniture, soaps, and food locally. Added bonus – Good Medicine is located right next door to Bonsai Brewery, a favorite spot of Meagan and Patrick's. Room prices start around $390 and there is a two night minimum.",
		],
		insideScoop: [
			"This is Meagan and Patrick’s favorite lodging choice because of its local feel, environmental consciousness, divine in-house roasted coffee, unique/cozy rooms, and wonderful staff. They have made all of the spaces in the lodge feel so personal and cozy – it's hard to want to leave the lodge! The daily breakfast and the cookies they serve in the afternoon are phenomenal. The location is also really convenient although you will need a car as they do not offer a shuttle service to town. The other consideration is that rooms are on the more expensive side, but we think it's worth the splurge!",
		],
	},
	{
		name: 'The Lodge at Whitefish Lake',
		address: '1380 Wisconsin Avenue, Whitefish, MT 59937',
		phoneNumber: '(406) 863-4000',
		imagePath: '/assets/accommodation-photos/whitefish-lake-lodge.png',
		link: 'https://lodgeatwhitefishlake.com/',
		rating: 4,
		review: [
			'The Lodge is located on Whitefish Lake, about a five minute drive from downtown Whitefish. It is the largest and nicest hotel in the area. There are a range of standard rooms, suites, 2-3 bedroom condos and luxury homes at prices starting at $600/night. The hotel does not offer room blocks, so book early if you would like to stay here. There is a 14 day cancellation policy and they require one night’s stay at the time of booking.  ',
			'There is a complimentary shuttle that goes between The Lodge and its sister hotel, The Firebrand Hotel, for easy access to downtown Whitefish. The shuttle is also complimentary to and from the airport. ',
		],
		insideScoop: [
			'This hotel offers a lovely setting on the shores of Whitefish Lake with a cozy Montana-esque lobby and full-service restaurant and bar. There is also an outdoor pool and a marina where you can rent a pontoon boat to explore the lake. Downside is that the prices are quite high and room quality is only slightly above the Firebrand (some rooms offer fireplaces which is a nice touch). You will also need to drive or take the shuttle to reach downtown restaurants and shops. ',
		],
	},
	{
		name: 'Hampton Inn & Suites Whitefish',
		address: '6340 US Hwy 93 South, Whitefish, MT 59937',
		phoneNumber: '(406) 730-8901',
		imagePath: '/assets/accommodation-photos/hampton-suites.webp',
		link: 'https://www.hilton.com/en/hotels/fcawfhx-hampton-suites-whitefish/',
		rating: 3,
		review: [
			'This is a new property situated along Highway 93 leading out of Whitefish toward Glacier National Park. We have a room block from Wed. Sept. 20 to Sunday, Sept. 24. There are standard king and double queen rooms (with a microwave and fridge) available for $269/night. Call 406-730-8901 and mention that you are part of the McKenzie-Sullivan wedding to receive our group rate. (The AAA rate is similar so you can just do that if you are a AAA member.) You can also upgrade to a studio suite with a sofa bed and wet bar for a minimal additional cost.  ',
			'Rooms include a daily hot breakfast and the hotel offers a complimentary airport shuttle and downtown shuttle until 10:45 pm. There is also an indoor pool, hot tub and 24 hour fitness center. Cancellation policy: 48 hours. ',
		],
		insideScoop: [
			'Haven’t visited this property yet but it is adjacent to many other similar hotels situated on a two-lane highway right outside of Whitefish, heading toward Glacier National Park. Good price for the area and convenient location although not a scenic area. Also will need to drive or catch the shuttle for the five-minute commute to downtown Whitefish.',
		],
	},
	{
		name: 'Towneplace Suites by Marriott Whitefish',
		address: '500 Akers Lane, Whitefish, MT 59937',
		phoneNumber: '(406) 890-2053',
		imagePath:
			'/assets/accommodation-photos/towneplace-suites-whitefish.webp',
		link: 'https://www.marriott.com/en-us/hotels/fcats-towneplace-suites-whitefish-kalispell/overview/',
		rating: 4,
		review: [
			'This is a new all-suite property situated along Highway 93 (across the street from the Hampton Inn). All the rooms include a fully-equipped kitchen and either a king or double queen beds. Some also offer sofa beds. Amenities include a hot breakfast, complimentary airport and downtown/train station shuttles, a fitness center, year-round outdoor heated pool and hot tub and an outdoor fire pit with adirondack chairs.',
			'To use the 15 percent off group rate code, go to the corporate promo code section when booking and enter W14. Rooms will be available 48 weeks in advance of our wedding date. Discounted rates range between $325/night and $400/night. Cancellation policy: 24 hours.',
		],
		insideScoop: [
			'Normally I wouldn’t give a chain hotel on a highway this high of a rating but I was impressed by how clean and attractive this hotel was. I really liked the public spaces and a full kitchen in the room is a nice feature. Also loved the great list of restaurants and area attractions on their website! No local vibe in the lobby or in the decor but good option if you want to stay somewhere comfortable, clean and modern!',
		],
	},
	{
		name: 'Grouse Mountain Lodge',
		address: '2 Fairway Drive, Whitefish, MT 59937',
		phoneNumber: '(406) 862-3000',
		imagePath: '/assets/accommodation-photos/grouse-mountain-lodge.webp',
		link: 'https://www.glacierparkcollection.com/lodging/grouse-mountain-lodge/',
		rating: 3,
		review: [
			'Classic convention hotel on the Whitefish Golf Course that has a rustic Montana vibe and spacious but somewhat dated rooms. Offers a nice screened porch gathering area that overlooks the golf course, a full service restaurant and bar and an indoor pool. Prices are $209/night for a standard king or double queen; $339/night for a loft room with a king and two twins in a separate bedroom. They offer a free shuttle to downtown every hour and a half and an airport shuttle for a small charge. ',
		],
		insideScoop: [
			'Features a cozy, rustic lobby with deer heads and bear skins on the walls. The screened porch is a great place to hang out if the weather is good and the golf course offers a nice view. Best hotel price in town for a standard room but it does have a bit of a throwback ‘80s feel in decor. Location is five minutes away from town so would need to take shuttle or drive into downtown Whitefish. ',
			'Please note, there are some lower priced Viking Lodge rooms at the Lodge that you can inquire about when booking. These nature-view rooms are located across the street from the waterfront Lodge rooms and connected to the main building through an interior walkway. They still offer all the amenities of staying at the main Lodge at Whitefish Lake.',
		],
	},
	{
		name: 'Apres Whitefish',
		address: '800 Spokane Avenue, Whitefish, MT 59937',
		phoneNumber: '(406) 862-8255',
		imagePath: '/assets/accommodation-photos/apres-whitefish.webp',
		link: 'https://www.apreswhitefish.com/',
		rating: 3,
		review: [
			'A newly remodeled hotel with a hipster vibe located along the same stretch of highway as the Hampton Inn and Towneplace Suites. The hotel offers a coffee bar and upscale room amenities such as luxury linens and vessel sinks. Some of the rooms have views of Big Mountain (the local ski resort) and some have a glimpse of the Whitefish River. There are a variety of room offerings from a single queen to a double queen plus bunk. Rates range from $266 to $320/night. ',
		],
	},
	{
		name: 'Clydesdale Outpost',
		address: '100 Outpost Drive, Whitefish, MT 59937',
		phoneNumber: '(406) 285-1165',
		imagePath: '/assets/accommodation-photos/clydesdale-outpost.webp',
		link: 'https://www.clydesdaleoutpost.com/',
		rating: 0,
		review: [
			'This is a brand new lodging establishment that opened in June. It consists of six two-bedroom, one bath cabins that have cedar hot tubs and kitchenettes and look out over the Stillwater River. There are Clydesdale horses on the property and you can take them out for a trail ride ($79) or for a carriage ride ($250). There are no TVs in the rooms; instead they are equipped with typewriters and record players. There is also no restaurant or coffee bar – you are on your own for food or can drive 10 minutes into Whitefish to find other options. Rates are $429/night for up to 4 people in a cabin.  ',
		],
	},
	{
		name: 'The Galleries at Whitefish',
		address: 'Whitefish',
		phoneNumber: '(406) 261-1971',
		imagePath: '/assets/accommodation-photos/galleries-at-whitefish.webp',
		link: 'https://www.thegalleriesofwhitefish.com/',
		rating: 4,
		review: [
			'This is a unique collection of condos that range from one bedroom/one bath to two bedrooms/two baths and all center on a shared lobby in a building in downtown Whitefish. They have only kitchenettes – no full kitchens – but are decorated in luxurious style. They offer reserved parking and common area washers and dryers. There is a three night minimum and prices range from $300/night to $615/night (before taxes, which are pretty steep). Would be great for a group who wants to stay downtown and have a common space to gather.',
		],
	},
];

export default lodgingInfo;
