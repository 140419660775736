import {
	Card,
	CardContent,
	Container,
	Divider,
	Stack,
	Typography,
} from '@mui/material';

import NewTabLink from './NewTabLink';
import PatrickOMeter from './PatrickOMeter';
import TextBlock from './TextBlock';
import { FoodInfo } from '../data/foodInfo';

type FoodProps = { food: FoodInfo };

// TODO enable 10/5 for the buff on the patrickometer
const Food = ({
	food: { restaurants, additionalRestaurants, coffee, beer },
}: FoodProps) => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Typography
			component="h1"
			variant="h3"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Food, Coffee & Beer in Whitefish
		</Typography>
		<Container sx={{ paddingBottom: 3 }}>
			<Typography
				component="h2"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				Restaurants
			</Typography>
			<Stack spacing={3}>
				{restaurants.map(({ name, description, link, rating }) => (
					<Card key={name} raised>
						<CardContent>
							<NewTabLink link={link} variant="h5">
								{name}
							</NewTabLink>
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
							<PatrickOMeter rating={rating} />
						</CardContent>
					</Card>
				))}
				<Card key="additional-restaurants" raised>
					<CardContent>
						<Typography
							component="h2"
							variant="h5"
							align="center"
							color="text.primary"
							gutterBottom
						>
							More Restaurant Options
						</Typography>
						<Divider />
						<TextBlock text={additionalRestaurants} />
					</CardContent>
				</Card>
			</Stack>
		</Container>
		<Container sx={{ paddingBottom: 3 }}>
			<Typography
				component="h2"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				Coffee
			</Typography>
			<Stack spacing={3}>
				{coffee.map(({ name, description, link }) => (
					<Card key={name} raised>
						<CardContent>
							<NewTabLink link={link} variant="h5">
								{name}
							</NewTabLink>
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
						</CardContent>
					</Card>
				))}
			</Stack>
		</Container>
		<Container sx={{ paddingBottom: 3 }}>
			<Typography
				component="h2"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				Beer
			</Typography>
			<Stack spacing={3}>
				{beer.map(({ name, description, link }) => (
					<Card key={name} raised>
						<CardContent>
							<NewTabLink link={link} variant="h5">
								{name}
							</NewTabLink>
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
						</CardContent>
					</Card>
				))}
			</Stack>
		</Container>
	</Container>
);

export default Food;
