import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import router from './router';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

if (typeof window !== 'undefined') {
	// eslint-disable-next-line no-console
	console.log(
		`%c

	  ██████╗    ██╗   ███╗   ███╗
	  ██╔══██╗   ██║   ████╗ ████║
	  ██████╔╝████████╗██╔████╔██║
	  ██╔═══╝ ██╔═██╔═╝██║╚██╔╝██║
	  ██║     ██████║  ██║ ╚═╝ ██║
	  ╚═╝     ╚═════╝  ╚═╝     ╚═╝
                P❤️M
  `,
		'font-family:monospace;color:#e32619;font-size:12px;'
	);
}
