import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Container,
	Stack,
	Typography,
} from '@mui/material';

import EmbeddedRegistry from './EmbeddedRegistry';
import NewTabLink from '../NewTabLink';

const Registry = (): JSX.Element => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Typography
			component="h1"
			variant="h4"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Registry
		</Typography>
		<Typography
			variant="subtitle1"
			color="text.primary"
			gutterBottom
			sx={{ pb: 2 }}
		>
			We are excited to celebrate with you. Your love and support at our
			wedding is an ample gift to us. For those who would nonetheless like
			to review our registry for gift ideas, please find our registry
			information below.
		</Typography>
		<Stack spacing={3}>
			<Card key="furniture-fund" raised>
				<CardContent>
					<Typography variant="h5" component="h2">
						Furniture Fund
					</Typography>
					<Typography variant="body1">
						As we create our new home together, we would sincerely
						appreciate a gift towards our &ldquo;furniture
						fund&rdquo;. To donate to our furniture fund, simply
						Venmo Meagan using follow the link below, or scan the
						QR-code below.
					</Typography>
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<NewTabLink link="https://venmo.com/u/Meagan-Mckenzie">
							Meagan&lsquo;s Venmo
						</NewTabLink>
					</Box>
				</CardContent>
				<CardMedia
					component="img"
					src="/assets/registry/venmo-qr.png"
					sx={{ width: '200px', margin: 'auto' }}
					alt={`Meagan's Venmo QR code image`}
				/>
			</Card>
			<Card key="fred-hutch-donation" raised>
				<CardContent>
					<Typography variant="h5" component="h2">
						Charitable Donation
					</Typography>
					<Typography variant="body1">
						In honor of Patrick&apos;s dad, Kevin, and Meagan&apos;s
						field of nursing (oncology), we established a charitable
						fund for donations towards the Fred Hutch Cancer Center.
						Fred Hutch provided excellent care to Kevin, as well as
						thousands of other patients.
					</Typography>
					<Box sx={{ width: '100%', textAlign: 'center', py: 3 }}>
						<NewTabLink link="https://secure.fredhutch.org/site/TR?px=2096836&pg=personal&fr_id=1575">
							Fred Hutch Cancer Center
						</NewTabLink>
					</Box>
				</CardContent>
			</Card>
			<Card key="embedded-registry" raised>
				<CardContent>
					<Typography variant="h5" component="h2">
						Registry
					</Typography>
					<Typography variant="body1">
						<>
							We also have{' '}
							<NewTabLink link="https://www.myregistry.com/giftlist/meagan-mckenzie-and-patrick-sullivan">
								our gift registry
							</NewTabLink>{' '}
							embedded below.
						</>
					</Typography>
					<EmbeddedRegistry />
				</CardContent>
			</Card>
		</Stack>
	</Container>
);

export default Registry;
