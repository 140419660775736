import { Box, CardMedia, CircularProgress } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const PHOTO_ALBUM_FOLDER = 'photo-album';

type PhotoCardProps = {
	name: string;
	height: number;
	width: number;
};

const PhotoCard = ({ name, height, width }: PhotoCardProps) => (
	<CardMedia sx={{ minHeight: (262 / width) * height }}>
		<LazyLoadImage
			alt={`Engagement photo ${name}`}
			src={`assets/${PHOTO_ALBUM_FOLDER}/${name}`}
			width="100%"
			effect="opacity"
			placeholder={
				<Box
					sx={{
						height: (262 / width) * height,
						textAlign: 'center',
					}}
				>
					<CircularProgress
						sx={{
							position: 'relative',
							top: '50%',
							transform: 'translateY(-50%)',
						}}
					/>
				</Box>
			}
		/>
	</CardMedia>
);

export default PhotoCard;
