import { Box, Container, Typography } from '@mui/material';

import CountdownTimer from './CountdownTimer';

const Homepage = () => (
	<>
		<Box
			sx={{
				height: '75vh',
				backgroundImage: 'url(/assets/main.webp)',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		/>
		<Box
			sx={{
				bgcolor: 'background.paper',
				pt: 8,
				pb: 6,
			}}
		>
			<Container maxWidth="sm">
				<Typography
					component="h1"
					variant="h2"
					align="center"
					color="text.primary"
					gutterBottom
				>
					Meagan &#38; Patrick
				</Typography>
				<CountdownTimer />
			</Container>
		</Box>
	</>
);

export default Homepage;
