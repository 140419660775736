import { Box, Typography } from '@mui/material';

import Copyright from './Copyright';

const Footer = () => (
	<Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
		<Typography
			variant="subtitle1"
			align="center"
			color="text.secondary"
			component="p"
		>
			All aboard the &ldquo;Sull&rdquo; train!
		</Typography>
		<Copyright />
	</Box>
);

export default Footer;
