import { Typography } from '@mui/material';

type TextBlockProps = {
	text: (string | JSX.Element)[];
};

const TextBlock = ({ text }: TextBlockProps) => (
	<>
		{text.map((value) => (
			<Typography
				variant="body1"
				align="justify"
				color="text.primary"
				sx={{ whiteSpace: 'pre-wrap' }}
				key={JSON.stringify(value)}
				paragraph
				gutterBottom
			>
				{value}
			</Typography>
		))}
	</>
);

export default TextBlock;
