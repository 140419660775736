import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography,
} from '@mui/material';

import JillOMeter from './JillOMeter';
import TextBlock from './TextBlock';
import { LodgingInfoArray } from '../data/lodgingInfo';

type LodgingProps = {
	lodgingInfo: LodgingInfoArray;
};

const Lodging = ({ lodgingInfo }: LodgingProps) => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Typography
			component="h1"
			variant="h4"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Lodging in Whitefish
		</Typography>
		<Typography
			component="h2"
			variant="h5"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Airbnb/VRBO
		</Typography>
		<Typography
			variant="body1"
			align="justify"
			sx={{ whiteSpace: 'pre-wrap' }}
			paragraph
		>
			If you are looking to stay with family or friends and pay a lower
			rate for the weekend, Airbnb/VRBO might be your best bet! There are
			many condos and houses within a reasonable walking or driving
			distance from downtown Whitefish. If you have any questions about
			location, please reach out to Meagan or Patrick. If the Airbnb
			you&apos;re renting is managed by &ldquo;Whitefish Escapes&rdquo;
			(e.g. the apartments in the Quarry, some homes in the area) there is
			a 5 percent discount for guests attending the wedding. Call
			Whitefish Escapes at (800) 870-1292 and say you are part of the
			&ldquo;McKenzie Sullivan Wedding&rdquo; to get a discount.
		</Typography>
		<Typography
			component="h2"
			variant="h5"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Hotels
		</Typography>
		<Stack spacing={3}>
			{lodgingInfo.map(
				({
					name,
					address,
					phoneNumber,
					imagePath,
					link,
					rating,
					review,
					insideScoop,
				}) => (
					<Card key={name} raised>
						<CardMedia
							component="img"
							src={imagePath}
							alt={`${name} image`}
						/>
						<CardContent>
							<Typography variant="h5" component="h2">
								{name}
							</Typography>
							<Typography variant="subtitle1">
								{address}
							</Typography>
							<Typography variant="subtitle1">
								{phoneNumber}
							</Typography>
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Review
							</Divider>
							<TextBlock text={review} />
							<JillOMeter rating={rating} />
							{insideScoop && (
								<>
									<Divider
										sx={{
											fontFamily:
												'Playfair Display SC,serif',
											paddingTop: '15px',
											fontWeight: 'bold',
										}}
									>
										Inside Scoop
									</Divider>

									<Typography
										variant="body1"
										align="justify"
										sx={{ whiteSpace: 'pre-wrap' }}
										paragraph
									>
										{insideScoop}
									</Typography>
								</>
							)}
						</CardContent>
						<CardActions>
							<Button
								sx={{ margin: 'auto', fontWeight: 'bold' }}
								variant="contained"
								target="_blank"
								rel="noopener noreferrer"
								endIcon={<OpenInNewTwoToneIcon />}
								href={link}
							>
								Learn More About This Hotel
							</Button>
						</CardActions>
					</Card>
				)
			)}
		</Stack>
	</Container>
);

export default Lodging;
