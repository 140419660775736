import NewTabLink from '../components/NewTabLink';

type EstablishmentInfo = {
	name: string;
	description: (string | JSX.Element)[];
	link: string;
};

type RestaurantInfo = {
	rating: number;
} & EstablishmentInfo;

export type FoodInfo = {
	restaurants: RestaurantInfo[];
	additionalRestaurants: (string | JSX.Element)[];
	coffee: EstablishmentInfo[];
	beer: EstablishmentInfo[];
};

const foodInfo: FoodInfo = {
	restaurants: [
		{
			name: 'The Buffalo Cafe',
			description: [
				"Patrick's favorite restaurant in the whole world!  A local hot-spot, the “Buff” offers dinner Monday-Friday and breakfast every day. It features great American fare with an emphasis on Montana-sourced ingredients. Patrick's go-to order is “Tutti's Mac N Cheese”, and Meagan's is the “Hot Date Burger.” Other must-tries include the parmesan french fries and homemade milkshakes (which are especially delicious for breakfast).",
			],
			link: 'https://buffalocafewhitefish.com/',
			rating: 5,
		},
		{
			name: 'Wich Haus',
			description: [
				"Meagan's favorite restaurant in the Flathead Valley. It's located in a cozy old home right outside Whitefish and whips up some amazing and unusual deli sandwiches, as well as fried chicken sandwiches and burgers. They make all their bread and desserts in house. They also have local brews on tap. Their outdoor seating is in a lovely garden setting.",
			],
			link: 'https://www.thewichhaus.com/',
			rating: 5,
		},
		{
			name: "Tupelo's",
			description: [
				"A classy Cajun-style restaurant in the midst of downtown Whitefish. It features live music, creative cocktails, and delicious food. It's a favorite date night spot of ours – a little more expensive, but a nice place for a special dinner.",
			],
			link: 'https://www.tupelogrille.com/',
			rating: 5,
		},
		{
			name: "Abruzzo's",
			description: [
				'A wonderful Italian restaurant in downtown Whitefish. The pizzas and pasta (and wine!) are all terrific. They have a great outside patio open in the summers too.',
			],
			link: 'https://www.abruzzoitaliankitchen.com/',
			rating: 5,
		},
		{
			name: 'Piggyback BBQ',
			description: [
				'Another favorite of ours with awesome BBQ plates, sandwiches, and hot dogs. They have some fun picnic tables outside and great local beer on tap.',
			],
			link: 'https://piggybackbbq.com/',
			rating: 5,
		},
	],
	additionalRestaurants: [
		<>
			{'A few other favorites include '}
			<NewTabLink link="https://amazingcrepes.com/">
				Amazing Crepes
			</NewTabLink>
			{', '}
			<NewTabLink link="https://www.whitefishrestaurant.com/">
				Loula’s Cafe
			</NewTabLink>
			{', and '}
			<NewTabLink link="https://www.swiftcreekcafe.com/">
				Swift Creek Cafe
			</NewTabLink>
			{' (all especially good for breakfast or lunch). '}
			<NewTabLink link="https://www.sweetpeaksicecream.com/whitefish">
				Sweet Peaks
			</NewTabLink>
			{' is a great place for ice cream. '}
			<NewTabLink link="https://greatnorthernbar.com/">
				The Great Northern
			</NewTabLink>
			{' is a classic Montana dive bar with great live music. '}
			<NewTabLink link="https://www.jerseyboyswhitefish.com/">
				Jersey Boys Pizza
			</NewTabLink>
			{' is great for a quick slice of pizza.'}
		</>,
	],
	coffee: [
		{
			name: 'Montana Coffee Traders',
			description: [
				"This cozy can’t-miss spot is a daily destination for the Sullivans. Patrick's favorite order is a Mayan Mocha (think spiced, sweet mocha) and a Mayhem bar (chocolate, oat, coconut perfection). This downtown coffee shop also features a great assortment of gift items and is attached to a fun boutique called the Toggery.",
			],
			link: 'https://coffeetraders.com/',
		},
		{
			name: 'Good Medicine Lodge',
			description: [
				<>
					{
						'A really special coffee experience is a coffee flight tasting at Good Medicine Lodge. This place roasts its own coffee and hosts informative and delicious pour-over coffee tastings on a sporadic basis. Check out '
					}
					<NewTabLink link="https://goodmedicinecoffee.com/collections/coffee-flights">
						their website
					</NewTabLink>
					{' for the most updated schedule'}
				</>,
			],
			link: 'https://goodmedicinecoffee.com/collections/coffee-flights',
		},
	],
	beer: [
		{
			name: 'Blackstar',
			description: [
				'Whitefish also has great beer. Our favorites are brewed at the location of our welcome party on Friday night: Blackstar, in downtown Whitefish. Featuring an open, modern design, this spot has plenty of seating, a rooftop, and yummy food. ',
			],
			link: 'https://www.blackstarmt.com/',
		},

		{
			name: 'Bonsai Brewing Project',
			description: [
				'This brewery has a great relaxed vibe and fun park-like outdoor seating with picnic tables. Bonsai brews its beer in-house and offers a unique and tasty menu (think Bánh mì, fancy hot dogs, and ahi tuna bowls).',
			],
			link: 'https://bonsaibrew.com/',
		},
		{
			name: 'Montana Tap House',
			description: [
				"This place has an upgraded dive bar feel. If you're from Seattle, it's like Chuck's Hop Shop. They have 50+ beers on tap and lots of couches and tables with seating for larger groups. It's right across the street from some of the lodging that is along Whitefish Lake (i.e. The Quarry, The Lodge at Whitefish Lake).",
			],
			link: 'https://www.montanatap.com/',
		},
	],
};

export default foodInfo;
