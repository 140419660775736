import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
	IconButton,
	Toolbar,
	Box,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { KeyboardEvent, MouseEvent, useState } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';

import {
	homeMap,
	LinkMap,
	photosMap,
	registryMap,
	RSVPMap,
	weddingInformationMap,
	whitefishInformationMap,
} from '../data/drawerLinks';

const drawerWidth = 240;

const TemporaryDrawer = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer =
		(open: boolean) => (event: KeyboardEvent | MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as KeyboardEvent).key === 'Tab' ||
					(event as KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			setDrawerOpen(open);
		};

	const getJSXList = (...maps: LinkMap[]): JSX.Element[] => {
		const JSXList: JSX.Element[] = [];
		maps.forEach((map, index) => {
			// eslint-disable-next-line react/no-array-index-key
			if (index !== 0) JSXList.push(<Divider key={index} />);
			map.forEach(({ icon, route }, listItem) => {
				JSXList.push(
					// eslint-disable-next-line react/no-array-index-key
					<ListItem key={listItem} disablePadding>
						<ListItemButton component={Link} to={route}>
							<ListItemIcon>{icon}</ListItemIcon>
							<ListItemText primary={listItem} />
						</ListItemButton>
					</ListItem>
				);
			});
		});
		return JSXList;
	};

	return (
		<>
			<IconButton
				onClick={toggleDrawer(true)}
				size="large"
				edge="start"
				color="inherit"
				aria-label="open drawer"
				sx={{ mr: 2 }}
			>
				<MenuTwoToneIcon />
			</IconButton>
			<Drawer
				anchor="left"
				open={drawerOpen}
				onClose={toggleDrawer(false)}
			>
				<Box
					sx={{ width: drawerWidth }}
					role="presentation"
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<Toolbar />
					<List>
						{getJSXList(
							homeMap,
							weddingInformationMap,
							whitefishInformationMap,
							RSVPMap,
							photosMap,
							registryMap
						)}
					</List>
				</Box>
			</Drawer>
		</>
	);
};

export default TemporaryDrawer;
