import AgricultureTwoToneIcon from '@mui/icons-material/AgricultureTwoTone';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import BrunchDiningTwoToneIcon from '@mui/icons-material/BrunchDiningTwoTone';
import CabinTwoToneIcon from '@mui/icons-material/CabinTwoTone';
import EmojiPeopleTwoToneIcon from '@mui/icons-material/EmojiPeopleTwoTone';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FlightTakeoffTwoToneIcon from '@mui/icons-material/FlightTakeoffTwoTone';
import HikingTwoToneIcon from '@mui/icons-material/HikingTwoTone';
import PhotoLibraryTwoToneIcon from '@mui/icons-material/PhotoLibraryTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import RsvpTwoToneIcon from '@mui/icons-material/RsvpTwoTone';

type LinkObject = {
	icon: JSX.Element;
	route: string;
};

export type LinkMap = Map<string, LinkObject>;

export const homeMap: LinkMap = new Map([
	['Home', { icon: <ArrowBackTwoToneIcon />, route: '/' }],
]);

export const weddingInformationMap: LinkMap = new Map([
	['Our Story', { icon: <FavoriteTwoToneIcon />, route: 'our-story' }],
	[
		'Bridal Party',
		{ icon: <EmojiPeopleTwoToneIcon />, route: 'bridal-party' },
	],
	[
		'Wedding Schedule',
		{ icon: <EventNoteTwoToneIcon />, route: 'wedding-schedule' },
	],
]);

export const whitefishInformationMap: LinkMap = new Map([
	[
		'Getting There',
		{ icon: <FlightTakeoffTwoToneIcon />, route: 'getting-there' },
	],
	[
		'Getting Around',
		{ icon: <AgricultureTwoToneIcon />, route: 'getting-around' },
	],
	['Lodging', { icon: <CabinTwoToneIcon />, route: 'lodging' }],
	['Food', { icon: <BrunchDiningTwoToneIcon />, route: 'food' }],
	['Activities', { icon: <HikingTwoToneIcon />, route: 'activities' }],
]);

export const RSVPMap: LinkMap = new Map([
	['RSVP', { icon: <RsvpTwoToneIcon />, route: 'rsvp' }],
]);

export const photosMap: LinkMap = new Map([
	['Photos', { icon: <PhotoLibraryTwoToneIcon />, route: 'photos' }],
]);

export const registryMap: LinkMap = new Map([
	['Registry', { icon: <RedeemTwoToneIcon />, route: 'registry' }],
]);
