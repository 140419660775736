import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Copyright = () => (
	<Typography variant="body2" color="text.secondary" align="center">
		{'Copyright © '}
		<Link color="inherit" component={RouterLink} to="/">
			Patrick &#38; Meagan
		</Link>{' '}
		{new Date().getFullYear()}.
	</Typography>
);

export default Copyright;
