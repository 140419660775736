import { createTheme, ThemeProvider } from '@mui/material';
import { FirebaseAppProvider } from 'reactfire';

import App from '../App';
import firebaseConfig from '../firebase';
import themeOptions from '../theme';

const theme = createTheme(themeOptions);

const Providers = () => (
	<FirebaseAppProvider firebaseConfig={firebaseConfig}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</FirebaseAppProvider>
);

export default Providers;
