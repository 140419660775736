import {
	Card,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Grid,
	Link,
	Stack,
	Typography,
} from '@mui/material';
import { differenceInDays } from 'date-fns';

import { bridalPartyInfoMap, Honorifics, Sex } from '../data/bridalParty';

const getRelationshipDuration = (dateMet: Date): string => {
	const now = new Date();
	const durationInDays = differenceInDays(now, dateMet);
	const durationInYears = (durationInDays / 365).toFixed(2);

	return `${durationInYears} years`;
};

const getHonorific = (
	bridesmaidStatus: boolean,
	honorStatus: boolean,
	sex: Sex
): Honorifics => {
	if (bridesmaidStatus) {
		if (honorStatus) return 'Maid of Honor';
		return sex === 'F' ? 'Bridesmaid' : 'Bridesman';
	}
	if (honorStatus) return 'Best Man';
	return sex === 'M' ? 'Groomsman' : 'Groomswoman';
};

const getBridalParty = (): JSX.Element[] => {
	const JSXList: JSX.Element[] = [];
	bridalPartyInfoMap.forEach(
		(
			{
				dateMet,
				bowlingScore,
				battingAvg,
				bridesmaidStatus,
				description,
				honorStatus,
				imagePath,
				karaokeSong,
				songLink,
				sex,
			},
			name
		) => {
			const [artist, song] = karaokeSong;
			JSXList.push(
				// eslint-disable-next-line react/no-array-index-key
				<Grid item key={name} xs={12} sm={6} md={4}>
					<Card
						sx={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}
						raised
					>
						<CardMedia
							component="img"
							src={imagePath}
							alt={`${name} image`}
						/>
						<CardContent sx={{ flexGrow: 1 }}>
							<Typography variant="h5" component="h2">
								{name}
							</Typography>
							<Typography variant="caption" fontWeight="bold">
								{getHonorific(
									bridesmaidStatus,
									honorStatus,
									sex
								)}
							</Typography>
							<Divider />
							<Stack direction="column">
								<Stack
									direction="row"
									spacing={2}
									justifyContent="space-between"
								>
									<Typography
										variant="caption"
										fontWeight="bold"
									>
										{`Knows ${
											bridesmaidStatus
												? 'Meagan'
												: 'Patrick'
										}`}
										:
									</Typography>
									<Typography variant="caption">
										{getRelationshipDuration(dateMet)}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									spacing={2}
									justifyContent="space-between"
								>
									<Typography
										variant="caption"
										fontWeight="bold"
									>
										Best bowling score:
									</Typography>
									<Typography variant="caption">
										{bowlingScore}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									spacing={2}
									justifyContent="space-between"
								>
									<Typography
										variant="caption"
										fontWeight="bold"
									>
										Batting average:
									</Typography>
									<Typography variant="caption">
										{battingAvg}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									spacing={2}
									justifyContent="space-between"
								>
									<Typography
										variant="caption"
										fontWeight="bold"
									>
										Karaoke song:
									</Typography>
									<Link
										href={songLink}
										target="_blank"
										rel="noopener noreferrer"
										variant="caption"
										align="right"
									>
										{`${artist} "${song}"`}
									</Link>
								</Stack>
							</Stack>
							<Divider />
							<Typography>{description}</Typography>
						</CardContent>
					</Card>
				</Grid>
			);
		}
	);
	return JSXList;
};

// TODO optimize the large photos
const BridalParty = () => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Grid container spacing={4}>
			{getBridalParty()}
		</Grid>
	</Container>
);

export default BridalParty;
