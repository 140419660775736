import { createBrowserRouter } from 'react-router-dom';

import Activities from './components/Activities';
import BridalParty from './components/BridalParty';
import ErrorPage from './components/ErrorPage';
import Food from './components/Food';
import GettingAround from './components/GettingAround';
import GettingThere from './components/GettingThere';
import Homepage from './components/Homepage';
import Lodging from './components/Lodging';
import OurStory from './components/OurStory';
import Photos from './components/Photos';
import Providers from './components/Providers';
import Registry from './components/registry/Registry';
import RSVP from './components/RSVP/RSVP';
import WeddingSchedule from './components/WeddingSchedule';
import activitiesInfo from './data/activityInfo';
import foodInfo from './data/foodInfo';
import lodgingInfo from './data/lodgingInfo';
import eventTimeline from './data/weddingScheduleInfo';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Providers />,
		errorElement: <ErrorPage />,
		// action: rsvpAction,
		children: [
			{
				index: true,
				element: <Homepage />,
			},
			{
				path: 'our-story',
				element: <OurStory />,
			},
			{
				path: 'bridal-party',
				element: <BridalParty />,
			},
			{
				path: 'wedding-schedule',
				element: <WeddingSchedule events={eventTimeline} />,
			},
			{
				path: 'getting-there',
				element: <GettingThere />,
			},
			{
				path: 'getting-around',
				element: <GettingAround />,
			},
			{
				path: 'lodging',
				element: <Lodging lodgingInfo={lodgingInfo} />,
			},
			{
				path: 'food',
				element: <Food food={foodInfo} />,
			},
			{
				path: 'activities',
				element: <Activities activities={activitiesInfo} />,
			},
			{
				path: 'rsvp',
				element: <RSVP />,
				// loader: rsvpLoader,
			},
			// {
			// path: 'rsvp/:rsvpId',
			// element: <RSVP />,
			// // loader: rsvpLoader,
			// },
			{
				path: 'photos',
				element: <Photos />,
			},
			{
				path: 'registry',
				element: <Registry />,
			},
		],
	},
]);

export default router;
