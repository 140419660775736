import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

import TextBlock from './TextBlock';
import gettingThereText from '../data/gettingThereText';

const GettingThere = () => {
	const flightsFromSeattleLink =
		'https://www.google.com/travel/flights/search?tfs=CBwQAhojagwIAhIIL20vMGQ5anISCjIwMjMtMDktMjFyBwgBEgNGQ0EaI2oHCAESA0ZDQRIKMjAyMy0wOS0yNHIMCAISCC9tLzBkOWpycAGCAQsI____________AUABSAGYAQE';
	const flightsFromIndyLink =
		'https://www.google.com/travel/flights/search?tfs=CBwQAhoeagcIARIDSU5EEgoyMDIzLTA5LTIxcgcIARIDRkNBGh5qBwgBEgNGQ0ESCjIwMjMtMDktMjRyBwgBEgNJTkRwAYIBCwj___________8BQAFIAZgBAQ';

	return (
		<Box
			sx={{
				bgcolor: 'background.paper',
				pt: 8,
				pb: 6,
			}}
		>
			<Container maxWidth="sm">
				<Typography
					component="h1"
					variant="h4"
					align="center"
					color="text.primary"
					gutterBottom
				>
					Getting to Whitefish
				</Typography>
				<TextBlock text={gettingThereText} />
				<Typography variant="h5" align="center" color="text.primary">
					Available Flights to Whitefish
				</Typography>
				<Stack
					sx={{ pt: 4 }}
					direction="row"
					spacing={2}
					justifyContent="center"
				>
					<Button
						sx={{ fontWeight: 'bold' }}
						variant="contained"
						target="_blank"
						rel="noopener noreferrer"
						endIcon={<OpenInNewTwoToneIcon />}
						href={flightsFromIndyLink}
					>
						Flights from Indy
					</Button>
					<Button
						sx={{ fontWeight: 'bold' }}
						variant="contained"
						target="_blank"
						rel="noopener noreferrer"
						endIcon={<OpenInNewTwoToneIcon />}
						href={flightsFromSeattleLink}
					>
						Flights from Seattle
					</Button>
				</Stack>
			</Container>
		</Box>
	);
};

export default GettingThere;
