type Dress = 'Casual Attire' | 'Semi-Formal Attire';

export type EventInfo = {
	title: string;
	date: string;
	time: string;
	location: string;
	mapLink: string;
	imagePath: string;
	dress: Dress;
	description: (string | JSX.Element)[];
	additionalNotes?: (string | JSX.Element)[];
};

const eventTimeline: EventInfo[] = [
	{
		title: 'Sneak "Peak" Party',
		date: 'Thursday, September 21st',
		time: '5:00 p.m. - 8:00 p.m.',
		location: 'Green Valley Ranch, Coram, Montana',
		mapLink:
			'https://www.google.com/maps/place/Green+Valley+Ranch/@48.4413489,-114.0296093,17z/data=!3m1!4b1!4m6!3m5!1s0x53663dfb5adbdf7b:0x51f98eedb002c6e5!8m2!3d48.4413489!4d-114.0270344!16s%2Fg%2F1hcb5j0mq',
		imagePath: '/assets/event-logos/cornhole.png',
		dress: 'Casual Attire',
		description: ['Yard games, mingling, bites & drinks'],
		additionalNotes: [
			<>
				This is an “early bird special” welcome party that is optional,
				but available for anyone who is in town early. We want to share
				the beautiful wedding venue with everyone in a more casual
				setting before the big day. The drive is about 30 minutes from
				Whitefish and transportation will{' '}
				<b>
					<em>not</em>
				</b>{' '}
				be provided for this event
			</>,
		],
	},
	{
		title: 'Welcome Pints',
		date: 'Friday, September 22nd',
		time: '7:30 p.m. - 10:00 p.m.',
		location: 'Blackstar Brewing, Whitefish, Montana',
		mapLink:
			'https://www.google.com/maps/place/Blackstar+BrewPub/@48.4123043,-114.3415273,17z/data=!3m1!4b1!4m6!3m5!1s0x536669d236dfd1f9:0xc4cfdd9450e603d9!8m2!3d48.4123044!4d-114.3369139!16s%2Fg%2F11s3cpmfy3',
		imagePath: '/assets/event-logos/beer.png',
		dress: 'Casual Attire',
		description: [
			'This is the welcome party for the wedding weekend and we look forward to celebrating with everyone!',
		],
	},
	{
		title: 'Wedding Ceremony & Reception',
		date: 'Saturday, September 23rd',
		time: '4:00 p.m.',
		location: 'Green Valley Ranch, Coram, Montana',
		mapLink:
			'https://www.google.com/maps/place/Green+Valley+Ranch/@48.4413489,-114.0296093,17z/data=!3m1!4b1!4m6!3m5!1s0x53663dfb5adbdf7b:0x51f98eedb002c6e5!8m2!3d48.4413489!4d-114.0270344!16s%2Fg%2F1hcb5j0mq',
		imagePath: '/assets/event-logos/hearts.png',
		dress: 'Semi-Formal Attire',
		description: ['The wedding!'],
		additionalNotes: [
			'For this event, shuttles to the venue are available and encouraged. They will pick up guests at 2:00 p.m. at the Lodge at Whitefish and at 2:30 p.m. at the Firebrand Hotel in Whitefish. Shuttles will depart Green Valley Ranch at 9:30 p.m., 10:30 p.m., and 12:30 a.m. to return to Whitefish. ',
			'Guests can also elect to drive their own cars. The drive from Whitefish to Green Valley Ranch is about 30 minutes.',
			'The ceremony will take place in a grassy area, so if you wear stiletto heels, you may become permanently affixed to the landscape. ',
			'There will be golf carts available for those who need help with transport around the property.',
			'September nights can get chilly in the mountains so consider bringing a light jacket.  There will be heaters in the tent to keep things toasty. Dancing is also available to stave off hypothermia.  ',
		],
	},
];

export default eventTimeline;
