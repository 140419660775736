import { CssBaseline } from '@mui/material';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './components/Footer';
import NavBar from './components/Navbar';

const App = () => (
	<>
		<CssBaseline />
		<NavBar />
		<main>
			<Outlet />
		</main>
		<Footer />
	</>
);

export default App;
