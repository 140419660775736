import { Link, LinkTypeMap } from '@mui/material';

type NewTabLinkProps = {
	link: string;
	children: string;
	variant?: LinkTypeMap['props']['variant'];
};

const NewTabLink = ({
	link,
	children,
	variant = undefined,
}: NewTabLinkProps) => (
	<>
		<Link
			href={link}
			target="_blank"
			rel="noopener noreferrer"
			variant={variant}
		>
			{children}
		</Link>
		<img
			style={{
				content:
					'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==)',
				margin: '0 0.1875rem 0 0.3125rem',
			}}
			alt="Opens in a new tab"
		/>
	</>
);

export default NewTabLink;
