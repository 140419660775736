import { FirebaseApp } from 'firebase/app';
import {
	Firestore,
	and,
	collection,
	doc,
	getFirestore,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { ObservableStatus, useFirestoreCollectionData } from 'reactfire';

import { Invitee } from './RSVPTypes';

export const useGetFirestoreInviteeByName = (
	firstName: string,
	lastName: string,
	app: FirebaseApp
): ObservableStatus<Invitee[]> => {
	const fireStoreInstance = getFirestore(app);
	const inviteesCollection = collection(fireStoreInstance, 'invitees');
	const inviteeGroupQuery = query(
		inviteesCollection,
		and(
			where('firstName', '==', firstName),
			where('lastName', '==', lastName)
		)
	);

	return useFirestoreCollectionData(inviteeGroupQuery, {
		idField: 'id',
	}) as ObservableStatus<Invitee[]>;
};

export const useGetFirestoreInviteesGroupByGroupId = (
	groupId: string,
	app: FirebaseApp
) => {
	const fireStoreInstance = getFirestore(app);
	const inviteesCollection = collection(fireStoreInstance, 'invitees');
	const inviteeGroupQuery = query(
		inviteesCollection,
		where('groupId', '==', groupId)
	);

	return useFirestoreCollectionData(inviteeGroupQuery, {
		idField: 'id',
	}) as ObservableStatus<Invitee[]>;
};

type UpdateInviteeParameters<K extends keyof Invitee> = {
	inviteeId: string;
	propertyToUpdate: K;
	updatedValue: Invitee[K];
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
	firestoreInstance: Firestore;
};

export const updateInvitee = <K extends keyof Invitee>({
	inviteeId,
	propertyToUpdate,
	updatedValue,
	setIsUpdateLoading,
	firestoreInstance,
}: UpdateInviteeParameters<K>) => {
	setIsUpdateLoading(true);
	const inviteeDocRef = doc(firestoreInstance, 'invitees', inviteeId);
	updateDoc(inviteeDocRef, { [propertyToUpdate]: updatedValue })
		.then(() => {
			if (import.meta.env.DEV) {
				console.log(
					`Successfully updated ${propertyToUpdate} to ${JSON.stringify(
						updatedValue
					)} for invitee with id ${inviteeId}`
				);
			}
			setIsUpdateLoading(false);
		})
		.catch((error) => {
			console.error(error);
			setIsUpdateLoading(false);
		});
};
