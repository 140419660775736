import {
	Typography,
	AppBar,
	Box,
	Link,
	Slide,
	Toolbar,
	useScrollTrigger,
} from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import TemporaryDrawer from './Drawer';

/*
const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));
*/

type Props = {
	children: React.ReactElement;
};

const HideOnScroll = (props: Props) => {
	const { children } = props;
	const trigger = useScrollTrigger();

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
};

const SearchAppBar = () => (
	<Box sx={{ flexGrow: 1 }}>
		<HideOnScroll>
			<AppBar>
				<Toolbar>
					<TemporaryDrawer />
					<Typography
						variant="h6"
						noWrap
						sx={{
							flexGrow: 1,
							display: { xs: 'none', sm: 'block' },
						}}
					>
						<Link
							color="inherit"
							// underline='none'
							component={RouterLink}
							to="/"
						>
							Patrick &#38; Meagan
						</Link>
					</Typography>
					{/* TODO Add search functionality */}
					{/* <Search>
						<SearchIconWrapper>
							<SearchTwoToneIcon />
						</SearchIconWrapper>
						<StyledInputBase
							placeholder="Search…"
							inputProps={{ 'aria-label': 'search' }}
						/>
					</Search> */}
				</Toolbar>
			</AppBar>
		</HideOnScroll>
		<Toolbar />
	</Box>
);

export default SearchAppBar;
