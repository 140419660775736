import { Favorite, FavoriteBorder } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { Firestore } from 'firebase/firestore';
import { ReactElement, useEffect } from 'react';
import { useFirestore } from 'reactfire';

import { updateInvitee } from '../../firestoreUtilities';
import {
	Invitee,
	InviteeBooleans,
	RehearsalDessert,
	RehearsalMeal,
} from '../../RSVPTypes';

const rehearsalMealOptions: RehearsalMeal[] = [
	'Chicken with Gnocchi',
	'Pasta Primavera',
	'Prime Rib',
];

const rehearsalDessertOptions: RehearsalDessert[] = [
	'Bread Pudding',
	'Huckleberry Crisp',
	'Key Lime Pie',
];

const isRehearsalFoodSelectionValid = (
	eventValue: unknown,
	validMeals: RehearsalMeal[] | RehearsalDessert[]
): boolean => validMeals.some((meal) => meal === eventValue);

export const areInviteesMealOptionsValid = (invitees: Invitee[]): boolean =>
	invitees.every(
		({ rehearsalMeal, rehearsalDessert, attendingRehearsal }) =>
			!attendingRehearsal ||
			(isRehearsalFoodSelectionValid(
				rehearsalMeal,
				rehearsalMealOptions
			) &&
				isRehearsalFoodSelectionValid(
					rehearsalDessert,
					rehearsalDessertOptions
				))
	);

const updatedHasResponded = (
	currentHasResponded?: boolean | string[]
): string[] => {
	if (
		typeof currentHasResponded === 'boolean' ||
		currentHasResponded === undefined
	)
		return [new Date().toISOString()];
	return [...currentHasResponded, new Date().toISOString()];
};

type UpdateInviteeSoUndefinedBooleansAreFalseParameters = {
	inviteeBooleans: Partial<InviteeBooleans>;
	inviteeId: string;
	firestoreInstance: Firestore;
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const updateInviteeSoUndefinedBooleansAreFalse = ({
	inviteeBooleans,
	inviteeId,
	firestoreInstance,
	setIsUpdateLoading,
}: UpdateInviteeSoUndefinedBooleansAreFalseParameters) => {
	const entriesToUpdate: Partial<InviteeBooleans> = {
		attendingRehearsal: inviteeBooleans.attendingRehearsal || undefined,
		attendingSneakPeak: inviteeBooleans.attendingSneakPeak || undefined,
		attendingWedding: inviteeBooleans.attendingWedding || undefined,
		invitedToRehearsal: inviteeBooleans.invitedToRehearsal || undefined,
		weddingShuttle: inviteeBooleans.weddingShuttle || undefined,
	};
	Object.entries(entriesToUpdate).forEach(([updateName, updateValue]) => {
		console.log({ updateName, updateValue });
		if (updateValue === undefined)
			updateInvitee({
				inviteeId,
				firestoreInstance,
				propertyToUpdate: updateName as keyof Invitee,
				updatedValue: false,
				setIsUpdateLoading,
			});
	});
};

type InviteeRSVPFormProps = {
	invitee: Invitee;
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteeRSVPForm = ({
	invitee: {
		id,
		hasResponded,
		displayName: { firstName, lastName },
		attendingSneakPeak = false,
		invitedToRehearsal = false,
		attendingRehearsal = false,
		rehearsalMeal = '' as RehearsalMeal,
		rehearsalDessert = '' as RehearsalDessert,
		dietaryRestrictions = '',
		attendingWedding = false,
		weddingShuttle = false,
	},
	setIsUpdateLoading,
}: InviteeRSVPFormProps): ReactElement => {
	const firestore = useFirestore();
	const isRehearsalMealValid = isRehearsalFoodSelectionValid(
		rehearsalMeal,
		rehearsalMealOptions
	);
	const isRehearsalDessertValid = isRehearsalFoodSelectionValid(
		rehearsalDessert,
		rehearsalDessertOptions
	);
	useEffect(() => {
		updateInvitee({
			inviteeId: id,
			propertyToUpdate: 'hasResponded',
			updatedValue: updatedHasResponded(hasResponded),
			firestoreInstance: firestore,
			setIsUpdateLoading,
		});
		// Default values to undefined
		updateInviteeSoUndefinedBooleansAreFalse({
			inviteeBooleans: {
				attendingSneakPeak,
				invitedToRehearsal,
				attendingRehearsal,
				weddingShuttle,
				attendingWedding,
			},
			inviteeId: id,
			firestoreInstance: firestore,
			setIsUpdateLoading,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Divider
					sx={{
						fontFamily: 'Playfair Display SC,serif',
						paddingTop: '30px',
						fontWeight: 'bold',
					}}
				>
					{`${firstName} ${lastName} RSVP selections:`}
				</Divider>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					required
					label={
						<>
							{`Is ${firstName} attending the `}
							&ldquo;<b>sneak peak</b>&rdquo; party (9/21 at 5
							p.m.)?
						</>
					}
					control={
						<Checkbox
							name="attendingSneakPeak"
							checked={attendingSneakPeak}
							onChange={(event) => {
								updateInvitee({
									inviteeId: id,
									propertyToUpdate: 'attendingSneakPeak',
									updatedValue: event.target.checked,
									setIsUpdateLoading,
									firestoreInstance: firestore,
								});
							}}
						/>
					}
				/>
			</Grid>
			{invitedToRehearsal && (
				<>
					<Grid item xs={12}>
						<FormControlLabel
							required
							label={
								<>
									{`Is ${firstName} attending the `}
									<b>rehearsal dinner</b> (9/22 at 5 p.m.)?
								</>
							}
							control={
								<Checkbox
									name="attendingRehearsal"
									checked={attendingRehearsal}
									onChange={(event) => {
										updateInvitee({
											inviteeId: id,
											propertyToUpdate:
												'attendingRehearsal',
											updatedValue: event.target.checked,
											setIsUpdateLoading,
											firestoreInstance: firestore,
										});
									}}
								/>
							}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							fullWidth
							id="rehearsalMeal"
							label={`${firstName}'s rehearsal dinner meal`}
							value={rehearsalMeal}
							helperText={
								attendingRehearsal && !isRehearsalMealValid
									? `Please select ${firstName}'s dinner option.`
									: null
							}
							disabled={!attendingRehearsal}
							error={attendingRehearsal && !isRehearsalMealValid}
							onChange={(event) => {
								const { value } = event.target;
								const valid = isRehearsalFoodSelectionValid(
									value,
									rehearsalMealOptions
								);
								if (valid)
									updateInvitee({
										inviteeId: id,
										propertyToUpdate: 'rehearsalMeal',
										updatedValue: value as RehearsalMeal,
										setIsUpdateLoading,
										firestoreInstance: firestore,
									});
							}}
						>
							{rehearsalMealOptions.map((mealLabel) => (
								<MenuItem
									key={mealLabel}
									value={mealLabel}
								>{`${mealLabel}`}</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							select
							fullWidth
							id="rehearsalDessert"
							label={`${firstName}'s rehearsal dinner dessert`}
							value={rehearsalDessert}
							helperText={
								attendingRehearsal && !isRehearsalDessertValid
									? `Please select ${firstName}'s dessert option.`
									: null
							}
							disabled={!attendingRehearsal}
							error={
								attendingRehearsal && !isRehearsalDessertValid
							}
							onChange={(event) => {
								const { value } = event.target;
								const valid = isRehearsalFoodSelectionValid(
									value,
									rehearsalDessertOptions
								);
								if (valid)
									updateInvitee({
										inviteeId: id,
										propertyToUpdate: 'rehearsalDessert',
										updatedValue: value as RehearsalDessert,
										setIsUpdateLoading,
										firestoreInstance: firestore,
									});
							}}
						>
							{rehearsalDessertOptions.map((dessertLabel) => (
								<MenuItem
									key={dessertLabel}
									value={dessertLabel}
								>{`${dessertLabel}`}</MenuItem>
							))}
						</TextField>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<FormControlLabel
					required
					label={
						<>
							{`Is ${firstName} attending the `}
							<b>wedding</b> (9/23 at 4 p.m.)?
						</>
					}
					control={
						<Checkbox
							name="attendingWedding"
							checked={attendingWedding}
							icon={<FavoriteBorder />}
							checkedIcon={<Favorite />}
							onChange={(event) => {
								updateInvitee({
									inviteeId: id,
									propertyToUpdate: 'attendingWedding',
									updatedValue: event.target.checked,
									setIsUpdateLoading,
									firestoreInstance: firestore,
								});
							}}
						/>
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					disabled={!attendingWedding}
					required
					label={
						<>
							{`Is ${firstName} taking the `}
							<b>wedding shuttle</b> (Whitefish to and from GVR)?
						</>
					}
					control={
						<Checkbox
							name="weddingShuttle"
							checked={weddingShuttle}
							onChange={(event) => {
								updateInvitee({
									inviteeId: id,
									propertyToUpdate: 'weddingShuttle',
									updatedValue: event.target.checked,
									setIsUpdateLoading,
									firestoreInstance: firestore,
								});
							}}
						/>
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="dietaryRestrictions"
					name="dietaryRestrictions"
					label={`Does ${firstName} have any dietary restrictions we should know about?`}
					fullWidth
					variant="outlined"
					value={dietaryRestrictions}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						updateInvitee({
							inviteeId: id,
							propertyToUpdate: 'dietaryRestrictions',
							updatedValue: event.target.value,
							setIsUpdateLoading,
							firestoreInstance: firestore,
						});
					}}
				/>
			</Grid>
		</Grid>
	);
};

type RSVPFormProps = {
	invitees: Invitee[];
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const RSVPForm = ({
	invitees,
	setIsUpdateLoading,
}: RSVPFormProps): JSX.Element =>
	invitees.length ? (
		<>
			<Typography variant="h6" gutterBottom>
				RSVP
			</Typography>
			<Typography variant="body1" gutterBottom>
				Please RSVP to the events below. Check the corresponding box if
				you plan on attending the event and leave unchecked if you
				cannot attend. Note that the wedding checkbox is a heart (♡).
			</Typography>
			<Typography variant="caption" gutterBottom>
				* is required.
			</Typography>
			<Box component="form" noValidate>
				{invitees.map((invitee) => (
					<InviteeRSVPForm
						key={invitee.id}
						invitee={invitee}
						setIsUpdateLoading={setIsUpdateLoading}
					/>
				))}
			</Box>
		</>
	) : (
		<>
			<Typography variant="h6" gutterBottom>
				RSVP
			</Typography>
			<Typography variant="body1" gutterBottom>
				No record of your invitation was found. Please ensure the
				spelling of your name matches your invite or try searching for
				another member of your party. If you continue to have trouble,
				please contact Meagan or Patrick.
			</Typography>
		</>
	);

export default RSVPForm;
