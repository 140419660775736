import {
	Card,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography,
} from '@mui/material';

import NewTabLink from './NewTabLink';
import TextBlock from './TextBlock';
import { ActivitiesInfo } from '../data/activityInfo';

const getHeaderComponent = (text: string, link?: string): JSX.Element =>
	link ? (
		<NewTabLink link={link} variant="h5">
			{text}
		</NewTabLink>
	) : (
		<Typography
			component="h2"
			variant="h5"
			align="left"
			color="text.primary"
		>
			{text}
		</Typography>
	);

type ActivitiesProps = {
	activities: ActivitiesInfo;
};

const Activities = ({
	activities: {
		activityNotes,
		additionalHikingResources,
		hikes,
		hikingNotes,
		moreActivities,
	},
}: ActivitiesProps) => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Typography
			component="h1"
			variant="h3"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Activities in Whitefish
		</Typography>
		<TextBlock text={activityNotes} />
		<Container sx={{ paddingBottom: 3 }}>
			<Typography
				component="h2"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				Hikes
			</Typography>
			<Stack spacing={3}>
				<Card key="hiking-notes" raised>
					<CardContent>
						<TextBlock text={hikingNotes} />
					</CardContent>
				</Card>
				{hikes.map(({ name, description, link, imagePath }) => (
					<Card key={name} raised>
						{imagePath && (
							<CardMedia
								component="img"
								src={imagePath}
								alt={`${name} image`}
							/>
						)}
						<CardContent>
							{getHeaderComponent(name, link)}
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
						</CardContent>
					</Card>
				))}
				<Card key="additional-hiking-resources" raised>
					<CardContent>
						<Typography
							component="h2"
							variant="h5"
							align="center"
							color="text.primary"
							gutterBottom
						>
							More Hiking Resources
						</Typography>
						<Divider />
						<TextBlock text={additionalHikingResources} />
					</CardContent>
				</Card>
			</Stack>
		</Container>
		<Container sx={{ paddingBottom: 3 }}>
			<Typography
				component="h2"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				More Activities
			</Typography>
			<Stack spacing={3}>
				{moreActivities.map(({ name, description, link }) => (
					<Card key={name} raised>
						<CardContent>
							{getHeaderComponent(name, link)}
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									paddingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
						</CardContent>
					</Card>
				))}
			</Stack>
		</Container>
	</Container>
);

export default Activities;
