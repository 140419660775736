import NewTabLink from '../components/NewTabLink';

const gettingThereText = [
	'Glacier Park International Airport (FCA) is the closest airport – located in Kalispell, twenty minutes from Whitefish. Many airlines fly into FCA – Delta, United, Alaska – but the flights can be pricey so we recommend booking soon. Experts recommend flying on Tuesdays, Thursdays, or Saturdays, and booking flights on Tuesdays after 2:00 p.m. or Thursdays for the most affordable rates.  ',
	<>
		{'Another option is to come by train. '}
		<NewTabLink link="https://www.amtrak.com/empire-builder-train">
			Amtrak’s Empire Builder
		</NewTabLink>
		{
			' runs from Chicago to Seattle and stops in Whitefish (WFH) or West Glacier (WGL)—just outside Glacier National Park.'
		}
	</>,
];

export default gettingThereText;
