import {
	Card,
	Container,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { getFirestore } from 'firebase/firestore';
import React, { useState } from 'react';
import { AppCheckProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';

import FunQuestions from './FunQuestions';
import NameLookup, { isNameValid } from './NameLookup';
import RSVPForm, { areInviteesMealOptionsValid } from './RSVPForm';
import RSVPNavButtons from './RSVPNavButtons';
import RSVPThankYou from './RSVPThankYou';
import {
	useGetFirestoreInviteeByName,
	useGetFirestoreInviteesGroupByGroupId,
} from '../../firestoreUtilities';
import { Invitee, FormNames } from '../../RSVPTypes';

const stepLabels = ['Name Lookup', 'RSVP', 'Other Fun Questions'];

type GetStepContentParameters = {
	activeStep: number;
	formNames: FormNames;
	setFormNames: React.Dispatch<React.SetStateAction<FormNames>>;
	invitees: Invitee[];
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
const getStepContent = ({
	activeStep,
	formNames,
	setFormNames,
	invitees,
	setIsUpdateLoading,
}: GetStepContentParameters) => {
	switch (activeStep) {
		case 0:
			return (
				<NameLookup formNames={formNames} setFormNames={setFormNames} />
			);
		case 1:
			return (
				<RSVPForm
					invitees={invitees}
					setIsUpdateLoading={setIsUpdateLoading}
				/>
			);
		case 2:
			return (
				<FunQuestions
					invitees={invitees}
					setIsUpdateLoading={setIsUpdateLoading}
				/>
			);
		case 3:
			return <RSVPThankYou invitees={invitees} />;
		default:
			throw new Error('Unknown step');
	}
};

type GetDisableNextButtonsParameters = {
	activeStep: number;
	invitees: Invitee[];
	firstName: string;
	lastName: string;
};
const getDisableNextButtons = ({
	activeStep,
	invitees,
	firstName,
	lastName,
}: GetDisableNextButtonsParameters): boolean => {
	switch (activeStep) {
		case 0:
			return !isNameValid(firstName) || !isNameValid(lastName);
		case 1:
			return !areInviteesMealOptionsValid(invitees);
		case 2:
		case 3:
			return false;
		default:
			throw new Error('Unknown step');
	}
};

const APP_CHECK_TOKEN = '6LehsrEmAAAAAGmW-F-EIESMXqzubNzsfOPwxnmu';

const RSVP = (): JSX.Element => {
	const app = useFirebaseApp();

	if (import.meta.env.DEV) {
		console.log('Local environment detected');
		// @ts-expect-error who knows?
		// eslint-disable-next-line no-restricted-globals
		self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
	}

	const appCheck = initializeAppCheck(app, {
		provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
		isTokenAutoRefreshEnabled: true,
	});

	const [activeStep, setActiveStep] = useState<number>(0);
	const [formNames, setFormNames] = useState<FormNames>({
		firstName: '',
		lastName: '',
	});
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);

	const { status: individualInviteeStatus, data: individualInviteeNameData } =
		useGetFirestoreInviteeByName(
			formNames.firstName.toLowerCase().trim(),
			formNames.lastName.toLowerCase().trim(),
			app
		);

	const groupId = individualInviteeNameData?.[0]?.groupId || '';

	const { status: groupQueryStatus, data: invitees } =
		useGetFirestoreInviteesGroupByGroupId(groupId, app);

	const disableNextButton = getDisableNextButtons({
		activeStep,
		invitees,
		firstName: formNames.firstName,
		lastName: formNames.lastName,
	});

	const isLoading =
		individualInviteeStatus === 'loading' ||
		groupQueryStatus === 'loading' ||
		isUpdateLoading;

	return (
		<AppCheckProvider sdk={appCheck}>
			<FirestoreProvider sdk={getFirestore(app)}>
				<Container sx={{ py: 8 }} maxWidth="sm" component="main">
					<Card
						sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
						raised
					>
						<Typography component="h2" variant="h4" align="center">
							Wedding Weekend RSVP
						</Typography>
						<Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
							{stepLabels.map((stepLabel) => (
								<Step key={stepLabel}>
									<StepLabel>{stepLabel}</StepLabel>
								</Step>
							))}
						</Stepper>
						{getStepContent({
							activeStep,
							formNames,
							setFormNames,
							invitees,
							setIsUpdateLoading,
						})}
						<RSVPNavButtons
							isLoading={isLoading}
							activeStep={activeStep}
							stepLabels={stepLabels}
							handleNext={() => {
								setActiveStep(activeStep + 1);
							}}
							disableNextButton={disableNextButton}
							handleBack={() => {
								setActiveStep(activeStep - 1);
							}}
						/>
					</Card>
				</Container>
			</FirestoreProvider>
		</AppCheckProvider>
	);
};

export default RSVP;
