import { Button, Card, CardActions, CardMedia, Modal } from '@mui/material';
import { ReactElement, useState } from 'react';

import { PHOTO_ALBUM_FOLDER } from './PhotoCard';

type PhotoModalProps = {
	name: string;
	height: number;
	width: number;
};

const PhotoModal = ({ name, height, width }: PhotoModalProps): ReactElement => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const imageAspectRatio = width / height;

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: `min(90vw, calc(90vh * ${imageAspectRatio}))`,
		height: `min(90vh, calc(90vw / ${imageAspectRatio}))`,
		maxHeight: '90vh',
		p: { xs: 2, md: 3 },
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
	};

	return (
		<CardActions>
			<Button onClick={handleOpen} size="small">
				Expand
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="Expand photo modal"
				aria-describedby="Expand photo modal"
			>
				<Card raised sx={style}>
					<CardMedia
						sx={{ maxHeight: 'calc(100% - 31px)' }}
						component="img"
						src={`assets/${PHOTO_ALBUM_FOLDER}/${name}`}
						alt={`${name} image`}
					/>
					<Button onClick={handleClose} size="small">
						Close
					</Button>
				</Card>
			</Modal>
		</CardActions>
	);
};

export default PhotoModal;
