const firebaseConfig = {
	apiKey: 'AIzaSyDN1sh-BP-RzamwVvNYzXYF9a9rHaerIyU',
	authDomain: 'patrick-and-meagan.firebaseapp.com',
	projectId: 'patrick-and-meagan',
	storageBucket: 'patrick-and-meagan.appspot.com',
	messagingSenderId: '847310169796',
	appId: '1:847310169796:web:559e05cba93dbcb2a64b91',
	measurementId: 'G-VR7LK5XF9K',
};

export default firebaseConfig;
