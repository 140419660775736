import { Container, Grid, Paper, styled } from '@mui/material';

import ourStoryMap, { Author } from '../data/ourStory';

type StyledPaperProps = {
	author: Author;
};

const meaganBackground = '#fefbf6';
const patrickBackground = '#e0ebeb';

const TextItem = styled(Paper)<StyledPaperProps>(({ theme, author }) => ({
	backgroundColor: author === 'Meagan' ? meaganBackground : patrickBackground,
	...theme.typography.body1,
	padding: theme.spacing(2),
	textAlign: author === 'Meagan' ? 'left' : 'right',
	color: theme.palette.text.primary,
}));

const getOurStoryText = (): JSX.Element[] => {
	const JSXList: JSX.Element[] = [];
	ourStoryMap.forEach((author, text) => {
		const authorLabel = author === 'Meagan' ? 'M: ' : 'P: ';
		JSXList.push(
			<Grid
				item
				xs={1}
				sm={3}
				md={3}
				key={`${String(text) + author}`}
				sx={{ alignSelf: `${author === 'Meagan' ? 'start' : 'end'}` }}
			>
				<Container maxWidth="sm">
					<TextItem author={author}>
						<b>{`${authorLabel} `}</b>
						{`${text}`}
					</TextItem>
				</Container>
			</Grid>
		);
	});
	return JSXList;
};

const OurStory = () => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Grid
			container
			direction="column"
			justifyContent="flex-start"
			alignItems="center"
			spacing={{ xs: 2, md: 3 }}
		>
			{getOurStoryText()}
		</Grid>
	</Container>
);

export default OurStory;
