import { Grid, TextField, Typography } from '@mui/material';

import { FormNames } from '../../RSVPTypes';

export const isNameValid = (event: unknown): boolean =>
	typeof event === 'string' && event.length > 0;

type NameLookupProps = {
	formNames: FormNames;
	setFormNames: React.Dispatch<React.SetStateAction<FormNames>>;
};

const NameLookup = ({
	formNames: { firstName, lastName },
	setFormNames,
}: NameLookupProps): JSX.Element => {
	const isFirstNameValid = isNameValid(firstName);
	const isLastNameValid = isNameValid(lastName);

	return (
		<>
			<Typography variant="h6" gutterBottom>
				Name Lookup
			</Typography>
			<Typography variant="body1" gutterBottom>
				Please enter a member of your party&apos;s first and last names
				as it appeared on the wedding invitation envelope.
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						fullWidth
						id="firstName"
						name="firstName"
						label="First name"
						autoComplete="given-name"
						variant="standard"
						helperText={
							!isFirstNameValid
								? 'Please enter your first name'
								: null
						}
						error={!isFirstNameValid}
						value={firstName}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => {
							const { value } = event.target;
							setFormNames((oldFormNames) => ({
								...oldFormNames,
								firstName: value,
							}));
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						required
						fullWidth
						id="lastName"
						name="lastName"
						label="Last name"
						autoComplete="family-name"
						variant="standard"
						helperText={
							!isLastNameValid
								? 'Please enter your last name'
								: null
						}
						error={!isLastNameValid}
						value={lastName}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => {
							const { value } = event.target;
							setFormNames((oldFormNames) => ({
								...oldFormNames,
								lastName: value,
							}));
						}}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default NameLookup;
