import React from 'react';

import NewTabLink from '../components/NewTabLink';

type Activity = {
	name: string;
	description: (string | JSX.Element)[];
	link?: string;
	imagePath?: string;
};

export type ActivitiesInfo = {
	activityNotes: (string | JSX.Element)[];
	hikes: Activity[];
	hikingNotes: (string | JSX.Element)[];
	additionalHikingResources: (string | JSX.Element)[];
	moreActivities: Activity[];
};

const activitiesInfo: ActivitiesInfo = {
	activityNotes: [
		"You're spoiled for choice of things to do in Whitefish and the Flathead Valley! You can have an adventure day in the mountains, a sun-soaked day on Whitefish Lake, or visit the cute boutiques in downtown Whitefish. Here are a few of our go-to activities to help you craft the perfect weekend in this beautiful place…",
	],
	hikingNotes: [
		'There are so many wonderful hikes in northwest Montana, you truly can’t go wrong! We have mostly spent time on the trails in the Whitefish area and Glacier National Park. There are lots of online resources to give you more information about hikes – and we are always available for a private consultation for a small fee.',
		<b>Please note a few things:</b>,
		<>
			{'We are lucky that it is late enough in the season that you do '}
			<em>
				<b>not</b>
			</em>
			{
				' need a pass to enter Glacier National Park over our wedding weekend. There is a fee to enter the park; if you have an annual national park pass, it is free to enter.'
			}
		</>,
		"It is important to carry bear spray when you hike and be educated on how to use it because of the grizzly bears in the area. You can't bring bear spray on a plane, but can easily buy it in town.",
		'Bring lots of layers while hiking in late September in Montana as the weather can change quickly, especially at higher elevations. We suggest bringing your hiking boots if you’re planning on hiking in Glacier.',
		'Here is a list of a few of our favorite trails (easiest to most difficult)...',
	],
	hikes: [
		{
			name: 'Skyles Lake',
			description: [
				"This is our favorite hike in the Whitefish area. It's about 2.6 miles and a very easy grade with a beautiful lookout at the end. A great mellow hike.",
			],
			link: 'https://www.google.com/maps/place/Skyles+Trailhead/@48.4085286,-114.4082714,15z/data=!4m2!3m1!1s0x0:0xe375de18130fb4af?sa=X&ved=2ahUKEwjrxYGL0If_AhV-AjQIHQxXARoQ_BJ6BAhMEAg',
			imagePath: '/assets/hikes/skyles-lake.webp',
		},
		{
			name: 'Hidden Lake Lookout',
			description: [
				'This trailhead is located in Glacier National Park and begins in the Logan Pass parking lot. This is a moderately difficult hike that is about 3 miles long. The trail takes you along the base of the mountains and pops you out at an overlook at a beautiful lake. A great hike if you are wanting something less strenuous but can get a little crowded as it is a popular trail.',
			],
			link: 'https://www.hikinginglacier.com/hidden-lake-overlook.htm',
			imagePath: '/assets/hikes/hidden-lake.webp',
		},
		{
			name: 'Danny On Trail',
			description: [
				'This is a trail that goes up Big Mountain (Whitefish Mountain Resort). It’s a pretty mellow grade but you end up climbing about 2,000 feet in 4 miles. From the summit of Big Mountain, you can peek into Glacier National Park and see all the ski lifts that we use in the winter! There are lots of beautiful views from this hike and it can be a shorter out-and-back hike if you desire.',
			],
			link: 'https://skiwhitefish.com/danny-on/',
			imagePath: '/assets/hikes/danny-on.webp',
		},
		{
			name: 'Highline Trail',
			description: [
				'This is located in Glacier National Park and accessed from the Logan Pass parking lot. The Highline Trail is a "choose-your-own-adventure":  it is a fairly flat out-and-back but has a number of side trails to make it more challenging if you want. One of our favorite side options is Haystack Butte. The Highline traverses the side of a mountain and one side is a steep drop off, so if you are fearful of heights, be aware! (That being said, Meagan’s cautious mom did it with us and she was fine!) We love this hike because you get an incredible view of one of the most beautiful valleys in Glacier and can see some awesome wildlife (mountain goats, bighorn sheep, marmots). It is a popular hike so expect some traffic on the trail.',
			],
			link: 'https://www.hikinginglacier.com/highline-loop.htm',
			imagePath: '/assets/hikes/highline.webp',
		},
		{
			name: 'Siyeh Pass',
			description: [
				"This is a great middle-of-the-road hike: less steep, but on the longer side. It is off the beaten path offers more solitude than some other hikes in Glacier. This trailhead is located 2-3 miles east of Logan Pass in Glacier National Park. It's a pretty easygoing gradient up to a pass, with a beautiful view into East Glacier from the top. It's around 9 miles roundtrip on an out-and-back trail.",
			],
			link: 'https://www.hikinginglacier.com/siyeh-pass.htm',
			imagePath: '/assets/hikes/siyeh.webp',
		},
		{
			name: 'Pitamakan-Dawson Loop',
			description: [
				'This is an incredible hike on the east side of Glacier at the Two Medicine entrance. It is about 19 miles and is pretty strenuous. We did this hike last summer and it was one of their favorites of all time. You climb along the base of mountains, up a pass to a lookout over a few lakes, and then back down into a valley with lots of flowers, wildlife and views. You can take a side-trip up Flinch Peak and add about 1,000 additional vertical feet: the views from the top are arresting.',
			],
			link: 'https://www.hikingproject.com/trail/7018717/pitamakandawson-loop',
			imagePath: '/assets/hikes/pitamakan.webp',
		},
	],
	additionalHikingResources: [
		<NewTabLink link="https://www.nps.gov/npgallery/GetAsset/9fe2fe28-43af-4574-8dde-2555a2657aca">
			Glacier National Park Map
		</NewTabLink>,
		<NewTabLink link="https://www.glacierparkcollection.com/GlacierParkInc/media/Documents/Maps/Whitefish-Trail-Map.pdf">
			Whitefish Trails Map
		</NewTabLink>,
		<NewTabLink link="https://www.nps.gov/glac/planyourvisit/trailstatusreports.htm">
			Glacier National Park Hiking conditions
		</NewTabLink>,
		<NewTabLink link="https://www.cntraveler.com/story/best-hikes-glacier-national-park?utm_source=onsite-share&utm_medium=email&utm_campaign=onsite-share&utm_brand=conde-nast-traveler">
			More hiking ideas in Glacier
		</NewTabLink>,
	],
	moreActivities: [
		{
			name: 'Boat, jet ski, kayak, and paddleboard rentals on Whitefish Lake',
			description: [
				'The easiest place to rent is The Marina at the Lodge at Whitefish Lake. September is the end of the high season in Whitefish so make sure to contact them online beforehand to see their inventory and availability. One warning: lake water might be quite cold for those not used to snow-fed lake temps! 😊',
			],
			link: 'https://lodgeatwhitefishlake.com/marina',
		},
		{
			name: 'Whitefish lake beaches',
			description: [
				'The main beach on Whitefish Lake is “City Beach” with a nice pebble beach area. There is also “Whitefish State Park” that has a spacious waterfront area for lounging and putting in a kayak or paddle board.',
			],
			link: 'https://explorewhitefish.com/entries/whitefish-city-beach/fcb53ba3-6bea-47e5-bd7f-947e8ff52718',
		},
		{
			name: 'Lake MacDonald',
			description: [
				'This lake is located right inside the entrance of Glacier National Park: there are a lot of spots where you can pull off along the road and have a picnic by the lake. There are also a number of public docks around the lake where you can put in your paddleboard or kayak, or go for a polar plunge!',
			],
			link: 'https://www.google.com/maps/place/Lake+McDonald,+Montana+59936/data=!4m2!3m1!1s0x53661bbe7f2d62f5:0xe1f1f691338e9e6d?sa=X&ved=2ahUKEwiAntroj4f_AhUUBzQIHRyBCBUQ8gF6BAh2EAE',
		},
		{
			name: 'River Rafting',
			description: [
				'Our wedding is at the very end of river rafting season for outfitters in Flathead. If you are planning on coming to Montana a few days earlier than the wedding you might catch them right before they close!',
				'We personally have never been river rafting in Montana but heard great things about Glacier Rafting Co. They offer all different lengths of trips, some including meals, etc. To make reservations or inquire more about trips you can visit the link above.',
			],
			link: 'https://www.glacierparkcollection.com/activities/glacier-raft-co/',
		},
		{
			name: 'Fly Fishing',
			description: [
				'We’ve had great experiences fly fishing through Glacier Anglers. We personally did a half day of fly fishing and that felt like the perfect amount of fishing for beginners. The instructors were excellent and, although expensive, we definitely felt like it was worth it!',
				'This activity will need to be booked in advance. You can visit their website for more information.',
			],
			link: 'https://www.glacierparkcollection.com/activities/glacier-anglers-outfitters/',
		},
		{
			name: 'Golfing',
			description: [
				'Whitefish Lake Golf Club is a public course offering two 18-hole experiences, the North and South Courses. WLGC is about 5 minutes from downtown Whitefish and offers beautiful lake and mountain views. For more information and to book a tee time, visit the link above.',
			],
			link: 'https://golfwhitefish.com/',
		},
		{
			name: 'Shopping in Whitefish',
			description: [
				'There are three to four blocks in Whitefish where most of the retail stores can be found. A few we suggest checking out are…',
				<>
					<b>The Toggery</b>: cute boutique with clothes for both men
					and women, little trinkets and locally made jewelry.
				</>,
				<>
					<b>Mum’s Flower Shop</b>: adorable shop bursting with
					beautiful blooms, cute mugs, genius cards–it’s a must visit!
				</>,
				<>
					<b>Voyageur Bookstore</b>: this is the best used bookstore
					in the WORLD in our opinion 😊 You’ve got to check it out if
					you love books. They also have fantastic homemade chocolate.
				</>,
				<>
					<b>Bookworm Bookstore</b>: another great bookstore in town.
					All new books and wonderful locally made cards.
				</>,
				<>
					<b>FoR Fine Art Gallery</b>: one of our favorite galleries
					in town. Always showing new, local artwork.
				</>,
				<>
					<b>The Walking Man Frame Shop & Gallery</b>: another awesome
					gallery and a great place to get a rustic picture frame to
					remember Montana by!
				</>,
				<>
					<b>Trovare</b>: really great kitchen store. They have 40+
					different types of olive oil and balsamic vinegars (think:
					jalapeno, smoked, herb & garlic). Super yummy.
				</>,
			],
			link: 'https://explorewhitefish.com/shop',
		},
		{
			name: 'Driving “Going to the Sun” Road',
			description: [
				'If you’re not up for a hike, you don’t have to miss out on Glacier’s beauty! The drive up Going to the Sun road gives you amazing views of the park and there are lots of places to pull off along the way to take pictures or soak it all in.',
			],
			link: 'https://www.nps.gov/thingstodo/drive-gtsr.htm',
		},
		{
			name: 'Drive to East Glacier',
			description: [
				'If you’ve been to Glacier a few times or are looking for something new, we suggest driving over to East Glacier and going into the Two Medicine or Many Glacier entrance. The drive around the park is gorgeous and is usually a lot less busy.',
				<NewTabLink link="https://www.nps.gov/glac/planyourvisit/hikingtwomedicine.htm">
					Two Medicine
				</NewTabLink>,
				<NewTabLink link="https://www.nps.gov/glac/planyourvisit/hikingmanyglacier.htm">
					Many Glacier
				</NewTabLink>,
			],
			link: 'https://www.nps.gov/glac/planyourvisit/hikingmanyglacier.htm',
		},
		{
			name: 'Red Bus Tour through Glacier',
			description: [
				'This is a relaxing way to see Glacier because you don’t have to worry about driving yourself! You’ll want to make this reservation in advance, departing from the West Glacier/Lake McDonald area. We’ve never gone on this tour, but hear great things about it. It’s old school open air buses that start at the West and East sides of Glacier to various areas. Your driver is also a guide that can share some of the history of Glacier along the way! For more information and reservation, visit the link.',
			],
			link: 'https://www.glaciernationalparklodges.com/red-bus-tours',
		},
		{
			name: 'Mountain biking',
			description: [
				<>
					{
						'Unfortunately the lifts going up Big Mountain for mountain biking will be closed by the time of our wedding weekend. That being said, if you want to bike up and down with all man-made power–be our guest! 😊  Our favorite place to rent bikes and gear is at '
					}
					<NewTabLink link="https://www.glaciercyclery.com/">
						Glacier Cyclery
					</NewTabLink>
				</>,
			],
		},
		{
			name: 'Visit surrounding areas: Eureka, Columbia Falls, Kalispell, Flathead Lake',
			description: [
				'All of these places have different vibes; it’s fun to explore and see some more areas in the Flathead Valley.. Most have a great brewery and a few cute shops on the main street of town. Flathead Lake has great boat rentals, SUP/kayak rentals, and swimming holes.',
			],
		},
	],
};

export default activitiesInfo;
