import NewTabLink from '../components/NewTabLink';

const gettingAroundText = [
	'We recommend renting a car so you can explore the area and suggest booking early for the best rates and availability.  Uber is available only in limited areas and is not a reliable source of transportation in the Flathead Valley. ',
	'Complimentary shuttles to Green Valley Ranch are encouraged.  The shuttles will depart at 2:00 p.m. at the Lodge at Whitefish and 2:30 p.m. at the Firebrand Hotel. The shuttles will depart Green Valley Ranch after the nuptials at 9:30 p.m., 10:30 p.m., and 12:30 a.m. to return to Whitefish.  Green Valley Ranch is a thirty minute drive from Whitefish. ',
	'There is no scheduled transportation for the Thursday night meet-and-greet party at Green Valley Ranch, the Friday night rehearsal dinner at Hellroaring Saloon on Big Mountain (for family and the wedding party), or for the Friday evening welcome party in Whitefish, so please rent a car if you are planning to attend those events.  ',
	'Car rentals are available at the airport or the Amtrak station. Turo is another option if rental cars have limited availability. ',
	'Some hotels provide round-trip shuttles to the airport (some only run during certain hours so check with your hotel for details and to make an advance reservation). Taxi service is also an option from the airport if you are in town for only a short time. ',
	<>
		<NewTabLink link="https://grabacabmt.com/">Grab A Cab</NewTabLink> is an
		excellent cab service in the Whitefish/Kalispell area that we have used
		for an airport run.
	</>,
	<>
		<NewTabLink link="https://www.mountainshuttlemt.com/">
			Mountain Shuttle
		</NewTabLink>
		This is a taxi service available in Whitefish that is a good option if
		you need a ride from the airport to lodging and don&apos;t want to rent
		a car. Guests will need to prearrange a ride at least 24 hours in
		advance — there are no taxis waiting at the airport. You can also
		contact them by phone at (406) 212-2149
	</>,
];
export default gettingAroundText;
