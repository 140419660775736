import {
	Card,
	CardContent,
	CardMedia,
	Container,
	Divider,
	Link,
	Stack,
	Typography,
} from '@mui/material';

import TextBlock from './TextBlock';
import { EventInfo } from '../data/weddingScheduleInfo';

type WeddingScheduleProps = {
	events: EventInfo[];
};

const WeddingSchedule = ({ events }: WeddingScheduleProps): JSX.Element => (
	<Container sx={{ py: 8 }} maxWidth="md">
		<Typography
			component="h1"
			variant="h4"
			align="center"
			color="text.primary"
			gutterBottom
		>
			Wedding Weekend Schedule
		</Typography>
		<Stack spacing={3}>
			{events.map(
				({
					title,
					date,
					time,
					location,
					mapLink,
					imagePath,
					dress,
					description,
					additionalNotes,
				}) => (
					<Card key={title} raised>
						<CardMedia
							component="img"
							src={imagePath}
							sx={{ width: '200px', margin: 'auto' }}
							alt={`${title} image`}
						/>
						<CardContent>
							<Typography variant="h5" component="h2">
								{title}
							</Typography>
							<Typography variant="subtitle1">
								{`${date}, ${time}`}
							</Typography>
							<Link
								href={mapLink}
								target="_blank"
								rel="noopener noreferrer"
								variant="subtitle1"
								align="right"
							>
								{location}
							</Link>
							<Typography variant="subtitle1">{dress}</Typography>
							<Divider
								sx={{
									fontFamily: 'Playfair Display SC,serif',
									addingTop: '15px',
									fontWeight: 'bold',
								}}
							>
								Description
							</Divider>
							<TextBlock text={description} />
							{additionalNotes && (
								<>
									<Divider
										sx={{
											fontFamily:
												'Playfair Display SC,serif',
											paddingTop: '15px',
											fontWeight: 'bold',
										}}
									>
										Please Note
									</Divider>
									<TextBlock text={additionalNotes} />
								</>
							)}
						</CardContent>
					</Card>
				)
			)}
		</Stack>
	</Container>
);

export default WeddingSchedule;
