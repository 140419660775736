import {
	Box,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { ReactElement } from 'react';

import { Invitee } from '../../RSVPTypes';

type GenerateAttendingStringParameters = {
	sneak_peak_party: boolean;
	rehearsal_dinner: boolean;
	wedding: boolean;
};
const generateEventsAttendingString = (
	params: GenerateAttendingStringParameters
): JSX.Element => {
	const eventsAttending = Object.entries(params)
		.filter(([, attending]) => attending)
		.map(([eventName]) => eventName.replaceAll('_', ' '));
	const numEvents = eventsAttending.length;
	switch (numEvents) {
		case 0:
			return (
				<>
					<b>no events</b>.
				</>
			);
		case 1:
			return (
				<>
					<b>{`the ${eventsAttending[0]}`}</b>.
				</>
			);
		case 2:
			return (
				<>
					<b>{`the ${eventsAttending[0]}`}</b> and{' '}
					<b>{`the ${eventsAttending[1]}`}</b>.
				</>
			);
		case 3:
			return (
				<>
					<b>{`the ${eventsAttending[0]}`}</b>,{' '}
					<b>{`the ${eventsAttending[1]}`}</b>, and{' '}
					<b>{`the ${eventsAttending[2]}`}</b>.
				</>
			);
		default:
			throw new Error('Unrecognized event number');
	}
};

type RSVPThankYouProps = {
	invitees: Invitee[];
};
const RSVPThankYou = ({ invitees }: RSVPThankYouProps): ReactElement => {
	const isAttendingWedding: boolean = invitees.some(
		(invitee) => invitee.attendingWedding
	);

	return (
		<>
			<Typography variant="body1">
				Thanks for RSVPing to our wedding!
			</Typography>
			{isAttendingWedding && (
				<Typography variant="body1">
					We&apos;re looking forward to you seeing you on September
					23rd!
				</Typography>
			)}
			<Typography variant="body1">
				Your RSVP information is confirmed as follows. Please go back if
				you need to change your response.
			</Typography>
			{invitees.map(
				({
					displayName: { firstName, lastName },
					attendingRehearsal,
					attendingSneakPeak,
					attendingWedding,
					invitedToRehearsal,
					rehearsalDessert,
					rehearsalMeal,
					weddingShuttle,
					dietaryRestrictions,
					danceSong,
					nameOfFirstChild,
				}) => (
					<Box key={`${firstName}-${lastName}`}>
						<Divider
							sx={{
								fontFamily: 'Playfair Display SC,serif',
								paddingTop: '15px',
								fontWeight: 'bold',
							}}
						>
							{`${firstName} ${lastName}`}
						</Divider>
						<List sx={{ listStyleType: 'disc', pl: 2 }}>
							<ListItem
								disableGutters
								sx={{ display: 'list-item', pb: 0 }}
							>
								<ListItemText
									primary={
										<>
											{`${firstName} is attending: `}
											{generateEventsAttendingString({
												rehearsal_dinner:
													attendingRehearsal,
												sneak_peak_party:
													attendingSneakPeak,
												wedding: attendingWedding,
											})}
										</>
									}
								/>
							</ListItem>
							{invitedToRehearsal && attendingRehearsal && (
								<ListItem
									disableGutters
									sx={{ display: 'list-item', pb: 0 }}
								>
									<ListItemText
										primary={
											<>
												{`For the rehearsal dinner, ${firstName} selected `}
												<b>{`${rehearsalMeal}`}</b> as
												their meal, and{' '}
												<b>{`${rehearsalDessert}`}</b>{' '}
												for dessert.
											</>
										}
									/>
								</ListItem>
							)}
							{attendingWedding && (
								<ListItem
									disableGutters
									sx={{ display: 'list-item', pb: 0 }}
								>
									<ListItemText
										primary={
											<>
												{`${firstName}`}
												<b>
													{weddingShuttle
														? ' is '
														: ' is not '}
												</b>
												taking the wedding shuttle.
											</>
										}
									/>
								</ListItem>
							)}
							{!!dietaryRestrictions && (
								<ListItem
									disableGutters
									sx={{ display: 'list-item', pb: 0 }}
								>
									<ListItemText
										primary={
											<>
												{`${firstName} informed us of the following dietary restrictions: `}
												<b>{`${dietaryRestrictions}`}</b>
												.
											</>
										}
									/>
								</ListItem>
							)}
							{!!danceSong && (
								<ListItem
									disableGutters
									sx={{ display: 'list-item', pb: 0 }}
								>
									<ListItemText
										primary={
											<>
												{`Additionally, ${firstName} solemnly swears to dance when `}
												<b>{`${danceSong.songName}`}</b>{' '}
												by{' '}
												<b>{`${danceSong.artist}`}</b>{' '}
												plays at the wedding reception.
											</>
										}
									/>
								</ListItem>
							)}
							{!!nameOfFirstChild && (
								<ListItem
									disableGutters
									sx={{ display: 'list-item', pb: 0 }}
								>
									<ListItemText
										primary={
											<>
												{`Finally, ${firstName}'s favorite name for Meagan and Patrick's first child is `}
												<b>{`${nameOfFirstChild}`}</b>.
											</>
										}
									/>
								</ListItem>
							)}
						</List>
					</Box>
				)
			)}
		</>
	);
};

export default RSVPThankYou;
