import { Box, Button, CircularProgress } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const getNextButtonContent = (
	activeStep: number,
	totalSteps: number,
	isLoading: boolean
): ReactElement => {
	if (isLoading) return <CircularProgress size={25} />;
	return activeStep === totalSteps - 1 ? <>Finish RSVP!</> : <>Next</>;
};

type RSVPNavButtonsProps = {
	isLoading: boolean;
	stepLabels: string[];
	activeStep: number;
	handleNext: () => void;
	disableNextButton: boolean;
	handleBack: () => void;
};

const RSVPNavButtons = ({
	isLoading,
	stepLabels,
	activeStep,
	handleNext,
	disableNextButton,
	handleBack,
}: RSVPNavButtonsProps) => (
	<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
		{activeStep !== 0 && (
			<Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
				{isLoading ? <CircularProgress size={25} /> : 'Back'}
			</Button>
		)}
		{activeStep < stepLabels.length ? (
			<Button
				variant="contained"
				onClick={handleNext}
				disabled={disableNextButton || isLoading}
				sx={{ mt: 3, ml: 1 }}
			>
				{getNextButtonContent(activeStep, stepLabels.length, isLoading)}
			</Button>
		) : (
			<Button
				variant="contained"
				component={Link}
				to="/"
				sx={{ mt: 3, ml: 1 }}
			>
				Done!
			</Button>
		)}
	</Box>
);

export default RSVPNavButtons;
