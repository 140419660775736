export type Author = 'Meagan' | 'Patrick';
type OurStory = Map<string, Author>;

const ourStoryMap: OurStory = new Map([
	[
		'I developed several coping mechanisms for the night shift doldrums while being a nurse at University of Washington Medical Center in Seattle.  A favorite was the “The Night Shifts before Christmas” tradition, where nurses dressed up or celebrated a festive theme on the shifts leading up to Christmas.  On a particularly memorable “Night Shift before Christmas” evening, I wore light-up Christmas earrings.  Little did I know, years later, when people ask Patrick how he knew he liked me the night we met, he says, “her light-up earrings.”',
		'Meagan',
	],
	[
		'Meagan marched into the hospital room on 7NE with bright Christmas light earrings, a Christmas sweater, and a beaming smile - characteristic Meagan.  Her presence hit me like a lightning bolt.  I made a great first impression with a comment about my soft Hoka running shoes, “It’s like running on gummy bears!” ',
		'Patrick',
	],
	[
		'I remember thinking, “Oh, this boy’s cute”, when I first saw Patrick in his Dad’s hospital room.  I liked Patrick’s family immediately; we talked about Boston and running and Christmas.  Patrick went home for a while that evening and his sister, Fallon, and I talked about her engagement. She discovered I was single, then she smiled and said, “Well, not to force anything on you.  But my brother’s one of the best people I know, if you’re interested.” ',
		'Meagan',
	],
	[
		'Patrick came back to the hospital around midnight.  When I came into his dad’s room, we talked about the stuff that’s still important to us: Montana, the outdoors, our families.  I left the room smiling.  He was a really great dude, and let me repeat, a cute one! ',
		'Meagan',
	],
	[
		'I knew Meagan’s twelve-hour shift ended at 7:00 a.m., so as the deadline loomed, at 5:00 a.m., I summoned the courage to ask her out, and rang my dad’s call light.  ',
		'Patrick',
	],
	[
		'Patrick’s dad’s nurse call-light rang and I scampered over to see Patrick standing in the doorway.  He smiled at me with his tired 5:00 a.m. eyes and said, “I know this is weird timing, but do you want to get coffee sometime?” ',
		'Meagan',
	],
	[
		'Meagan and I met on December 9, 2019, when my dad was in the hospital for skin cancer.  My dad passed away on January 7, 2020.  That period was agonizing for my family.  Although we had only recently met, Meagan steadfastly supported my family and me.  I am happy that she was able to meet my dad.  He told Meagan, “You’ve really captured Patrick’s heart.” ',
		'Patrick',
	],
	[
		'Our relationship formed during one of the hardest times of Patrick’s life, while Patrick’s dad was hospitalized and near the end of his life.  Our first dates were filled with laughs, first touches, and sweet texts.  But also really devastating moments and vulnerable conversations.  It was truly an embodiment of how joy and grief can both inhabit a body at the same time and how challenging and beautiful that is. ',
		'Meagan',
	],
	[
		'Authenticity is the bedrock of our relationship.  When Meagan and I met, there was no pretext or facade – we were ourselves, and I loved Meagan from the start.  ',
		'Patrick',
	],
	[
		'I knew our first date was a good one.  We went to a coffee shop and Patrick ordered a cookie with our coffees – a big YES for me.  And then an ant ran across our cookie and Patrick still ate it – another big YES for me! ',
		'Meagan',
	],
	[
		'On our first date, Meagan and I talked for hours.  I was surprised at the end of the night when the barista told us they were closing.  ',
		'Patrick',
	],
	[
		'Some of our favorite memories from dating are building a chicken coop for our six chicks, growing our backyard garden, and climbing mountains with each other and some of our best friends.  Snuggles after hard days at work, forming memories with both our families, and building deep friendships in our community.  Our time in Montana together, reading poetry in our reading room, and water-coloring while backpacking.  And learning so gosh darn much about ourselves and each other.  One of our favorite memories is just being together. Through all the memorable experiences we’ve shared, it’s the small moments, in between, that are most important.',
		'Meagan',
	],
	[
		'There are few big events in life, but meeting Meagan was one of mine.  This event demarcates my life: life before Meagan, and now, and I couldn’t be happier.  ',
		'Patrick',
	],
	[
		'Over Labor Day 2022, Meagan and I went on a 4-day backpacking trip to the Sawtooth Mountains in Washington. After asking Meagan’s parents and her sister, Molly, for their blessings, I planned to propose to Meagan the first night of our trip.',
		'Patrick',
	],
	[
		'Patrick had kind of been a pain in the butt that week with how particular he was about where we went backpacking over Labor Day. He kept saying he needed somewhere with the “best” views, or the “perfect” spot. I kept telling him that  I was his best view.  ;)  Little did I know he wanted us to go somewhere that was engagement material!',
		'Meagan',
	],
	[
		'I wanted to propose the first night that we were backpacking, and the lake adjacent to our campsite was beautiful, but I didn’t think it was quite “engagement material.” So I patiently waited with the engagement ring at the bottom of my sleeping bag until the right moment.',
		'Patrick',
	],
	[
		' On the third morning of our trip, we decided to wake up to summit Courtney Peak for sunrise. We rolled out of sleeping bags when our alarm went off, threw on our boots and headed up the trail.  Note that our teeth were not brushed, hair was in disarray, and dirt was under fingernails. When we made it to the top, the sun was just coming over the horizon and lit up the entire North Cascade range in front of us. It was unreal.',
		'Meagan',
	],
	[
		'I told Meagan I was setting up my phone to take a time lapse video, so I didn’t arouse suspicion from Meagan. I was actually trying to frame the photo so we could have a video of the proposal. To ensure I framed the shot, I went and sat beside Meagan while the video was recording, then went back to check the results.  I’m glad I did, because my pants were riding like a plumber’s!   I hitched up my drawers and remedied the issue.',
		'Patrick',
	],
	[
		'Patrick came over and stood in front of me just after the sun had risen and we were taking in the beautiful views. I looked up at him and he had this sweet, teary-eyed look. I asked him, “What’s wrong?” and his eyes just got more teary! He then pulled a box out of his pocket, went down on one knee and asked me to marry him!!!!!  I was so surprised I asked him, “Are you for real!?” He laughed and said, “Of course I am!”',
		'Meagan',
	],
	[
		'We sent our families a message on our satellite communication device to let them know Meagan and I were engaged. We treasured spending the next 48 hours, just the two of us, in our favorite place in the world, the mountains, to celebrate our engagement. ',
		'Patrick',
	],
	[
		'When thinking about where we wanted to get married we knew it had to be one of our “heart” places - somewhere where our hearts were full. Montana is a place that has always felt that way to us, and somewhere we’ve always wanted to share with our people.',
		'Meagan',
	],
	[
		'We can’t wait to share Montana with you on our wedding weekend. It’s a piece of heaven and a place Meagan and I fell in love. If you’re able to make it to our wedding, we’re so thankful for your support of our relationship.',
		'Patrick',
	],
]);

export default ourStoryMap;
