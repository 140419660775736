export type Honorifics =
	| 'Groomsman'
	| 'Groomswoman'
	| 'Best Man'
	| 'Bridesmaid'
	| 'Bridesman'
	| 'Maid of Honor';

export type Sex = 'M' | 'F';

type BridalPartyInfo = {
	dateMet: Date;
	bowlingScore: number;
	battingAvg: string;
	bridesmaidStatus: boolean;
	description: string;
	honorStatus: boolean;
	imagePath: string;
	karaokeSong: [string, string];
	songLink: string;
	sex: Sex;
};

type BridalPartyInfoMap = Map<string, BridalPartyInfo>;

export const bridalPartyInfoMap: BridalPartyInfoMap = new Map([
	[
		'Molly McKenzie',
		{
			dateMet: new Date(1997, 5, 3),
			bowlingScore: 99,
			battingAvg: '.402',
			bridesmaidStatus: true,
			honorStatus: true,
			description: 'Mooly!',
			imagePath: '/assets/bridal-party/molly-mckenzie.webp',
			karaokeSong: ['Maggie Rogers', 'Alaska'],
			songLink:
				'https://open.spotify.com/track/4HfLQJtVT1KiX1eVedDyTm?si=3ea29f0536d84d03',
			sex: 'F',
		},
	],
	[
		'Maggie Moellering',
		{
			dateMet: new Date(2017, 7, 1),
			bowlingScore: Math.floor(Math.random() * 300),
			battingAvg: '.348',
			bridesmaidStatus: true,
			honorStatus: false,
			description: 'Maggie!',
			imagePath: '/assets/bridal-party/maggie-moellering.webp',
			karaokeSong: ['Abba', 'Angeleyes'],
			songLink:
				'https://open.spotify.com/track/7rWgGyRK7RAqAAXy4bLft9?si=97c7dd920e74446f',
			sex: 'F',
		},
	],
	[
		'Haley Skinner',
		{
			dateMet: new Date(1999, 0, 1),
			bowlingScore: 93,
			battingAvg: '.667',
			bridesmaidStatus: true,
			honorStatus: false,
			description: 'Haley!',
			imagePath: '/assets/bridal-party/haley-skinner.webp',
			karaokeSong: ['Ziggy Alberts', 'Gone'],
			songLink:
				'https://open.spotify.com/track/3DNvOV8PdEp1FNTqXUGBQF?si=4eba2ef02bc64ee7',
			sex: 'F',
		},
	],
	[
		'Matt Phelps',
		{
			dateMet: new Date(2013, 8, 1),
			bowlingScore: 123,
			battingAvg: '.369',
			bridesmaidStatus: true,
			honorStatus: false,
			description: 'Matt!',
			imagePath: '/assets/bridal-party/matthew-phelps.webp',
			karaokeSong: ['MUNA', 'I Know a Place'],
			songLink:
				'https://open.spotify.com/track/0bPSRn4crnh5f1JhELPlyL?si=cbfa30e0f9c04ee6',
			sex: 'M',
		},
	],
	[
		'Alexa Bossingham',
		{
			dateMet: new Date(2009, 8, 1),
			bowlingScore: 245,
			battingAvg: '.500',
			bridesmaidStatus: true,
			honorStatus: false,
			description: 'Alexa!',
			imagePath: '/assets/bridal-party/alexa-bossingham.webp',
			karaokeSong: ['Taylor Swift', 'Untouchable'],
			songLink:
				'https://open.spotify.com/track/0tQ9vBYpldCuikPsbgOVKA?si=0c751045c2424e7e',
			sex: 'F',
		},
	],
	[
		'Ryan LeClaire',
		{
			dateMet: new Date(2006, 7, 1),
			bowlingScore: 262,
			battingAvg: '.103',
			bridesmaidStatus: false,
			honorStatus: true,
			description: 'RyJayJay!',
			imagePath: '/assets/bridal-party/ryan-leclaire.webp',
			karaokeSong: ['Aaron Neville', 'Everybody Plays The Fool'],
			songLink:
				'https://open.spotify.com/track/6VthESmOYTaHBh1g0pacba?si=04fc5d5fed17458d',
			sex: 'M',
		},
	],
	[
		'Dustin Steffan',
		{
			dateMet: new Date(2007, 10, 7),
			bowlingScore: 78,
			battingAvg: '.104',
			bridesmaidStatus: false,
			honorStatus: false,
			description: 'Dusty!',
			imagePath: '/assets/bridal-party/dustin-steffan.webp',
			karaokeSong: ['Los Del Rio', 'Macarena'],
			songLink:
				'https://open.spotify.com/track/4oaj36KzXRgDg4McgcTsZK?si=270b2e1a84f54f07',
			sex: 'M',
		},
	],
	[
		'Pablo Dipascuale',
		{
			dateMet: new Date(2006, 7, 1),
			bowlingScore: 8,
			battingAvg: '.348',
			bridesmaidStatus: false,
			honorStatus: false,
			description: 'Pablo!',
			imagePath: '/assets/bridal-party/pablo-dipascuale.webp',
			karaokeSong: ['Squeeze', 'Tempted'],
			songLink:
				'https://open.spotify.com/track/5r2z0FHOoFI06GZEVGDjzM?si=41d8e0014cf5420d',
			sex: 'M',
		},
	],
	[
		'Anna Sullivan',
		{
			dateMet: new Date(1989, 4, 21),
			bowlingScore: 300,
			battingAvg: '.014',
			bridesmaidStatus: false,
			honorStatus: false,
			description: 'Anna Banana!',
			imagePath: '/assets/bridal-party/anna-sullivan.webp',
			karaokeSong: ['Jason Derulo', 'Savage Love'],
			songLink:
				'https://open.spotify.com/track/7GZT8wg2vdwU23mj0KYXKQ?si=6d5b186399c04e75',
			sex: 'F',
		},
	],
	[
		'Fallon Sullivan',
		{
			dateMet: new Date(1991, 2, 3),
			bowlingScore: 36,
			battingAvg: '1.000',
			bridesmaidStatus: false,
			honorStatus: false,
			description: 'Falkin Tater!',
			imagePath: '/assets/bridal-party/fallon-sullivan.webp',
			karaokeSong: ['Aaron Carter', "Aaron's Party"],
			songLink:
				'https://open.spotify.com/track/4fF34Yf5KsfUbCORyLGpEj?si=cd0b5ea82f7747e1',
			sex: 'F',
		},
	],
]);
