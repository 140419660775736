import { Box, Container, Typography } from '@mui/material';

import TextBlock from './TextBlock';
import gettingAroundText from '../data/gettingAroundText';

const GettingAround = () => (
	<Box
		sx={{
			bgcolor: 'background.paper',
			pt: 8,
			pb: 6,
		}}
	>
		<Container maxWidth="sm">
			<Typography
				component="h1"
				variant="h4"
				align="center"
				color="text.primary"
				gutterBottom
			>
				Getting Around the Flathead Valley
			</Typography>
			<TextBlock text={gettingAroundText} />
		</Container>
	</Box>
);

export default GettingAround;
