import { Box } from '@mui/material';
import { ReactElement, useEffect } from 'react';

const EmbeddedRegistry = (): ReactElement | null => {
	useEffect(() => {
		const script = document.createElement('script');

		// script.id = 'script_myregistry_giftlist_iframe';
		// script.async = true;
		script.type = 'text/javascript';
		script.src =
			'//www.myregistry.com//Visitors/GiftList/iFrames/EmbedRegistry.ashx?r=U5ItlhMZxThaM4hdPZBb_w2&v=2';

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return <Box id="script_myregistry_giftlist_iframe" />;
};

export default EmbeddedRegistry;
