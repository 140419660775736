import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useFirestore } from 'reactfire';

import { updateInvitee } from '../../firestoreUtilities';
import { Invitee } from '../../RSVPTypes';

type InviteeFunQuestionsProps = {
	invitee: Invitee;
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
const InviteeFunQuestions = ({
	invitee: {
		id,
		displayName: { firstName, lastName },
		attendingWedding = false,
		nameOfFirstChild = '',
		danceSong = { songName: '', artist: '' },
	},
	setIsUpdateLoading,
}: InviteeFunQuestionsProps): ReactElement | null => {
	const { songName, artist } = danceSong;
	const firestore = useFirestore();

	return attendingWedding ? (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Divider
					sx={{
						fontFamily: 'Playfair Display SC,serif',
						paddingTop: '15px',
						fontWeight: 'bold',
					}}
				>
					{`${firstName} ${lastName}'s Fun Questions:`}
				</Divider>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">
					What song will compel your body to the dance floor?
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					id="danceSong-songName"
					name="danceSong-songName"
					label="Song name"
					fullWidth
					variant="standard"
					value={songName}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						updateInvitee({
							inviteeId: id,
							propertyToUpdate: 'danceSong',
							updatedValue: {
								...danceSong,
								songName: event.target.value,
							},
							setIsUpdateLoading,
							firestoreInstance: firestore,
						});
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					id="danceSong-artist"
					name="danceSong-artist"
					label="Artist"
					fullWidth
					variant="standard"
					value={artist}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						updateInvitee({
							inviteeId: id,
							propertyToUpdate: 'danceSong',
							updatedValue: {
								...danceSong,
								artist: event.target.value,
							},
							setIsUpdateLoading,
							firestoreInstance: firestore,
						});
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">
					What should Meagan and Patrick name their first child?
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="nameOfFirstChild"
					name="nameOfFirstChild"
					label="Name of Meagan & Patrick's first child"
					fullWidth
					variant="standard"
					value={nameOfFirstChild}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						updateInvitee({
							inviteeId: id,
							propertyToUpdate: 'nameOfFirstChild',
							updatedValue: event.target.value,
							setIsUpdateLoading,
							firestoreInstance: firestore,
						});
					}}
				/>
			</Grid>
		</Grid>
	) : null;
};

type FunQuestionsProps = {
	invitees: Invitee[];
	setIsUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const FunQuestions = ({
	invitees,
	setIsUpdateLoading,
}: FunQuestionsProps): JSX.Element => (
	<>
		<Typography variant="h6" gutterBottom>
			Fun questions
		</Typography>
		<Typography variant="body1" gutterBottom>
			These are additional fun questions for those who are attending the
			wedding. Please answer if you want to.
		</Typography>
		<Box component="form" noValidate>
			{invitees.map((invitee) => (
				<InviteeFunQuestions
					key={invitee.id}
					invitee={invitee}
					setIsUpdateLoading={setIsUpdateLoading}
				/>
			))}
		</Box>
	</>
);

export default FunQuestions;
