import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const scale = 5;

type Props = {
	rating: number;
};

const PatrickOMeter = ({ rating }: Props) => {
	const star = 'patrick-head.png';
	const unstar = 'empty-patrick-head.png';
	const sanitizedRating = rating !== 0 ? rating : 4;

	const JSXElements = Array.from({ length: scale }, (_v, index) => (
		<Box
			key={index}
			sx={{
				height: '44px',
				width: '44px',
				backgroundImage: `url(/assets/patrick-rating/${
					index < sanitizedRating ? star : unstar
				})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				filter: 'drop-shadow(2px 2px 2px #666666)',
				WebkitFilter: 'drop-shadow(2px 2px 2px #666666)',
			}}
		/>
	));

	return (
		<Stack margin="auto" width="220px">
			<Stack justifyContent="center" direction="row">
				{JSXElements}
			</Stack>
			<Typography
				sx={{ margin: 'auto' }}
				variant="caption"
				align="center"
				fontFamily="Playfair Display SC,serif"
				fontWeight="bold"
			>{`Patrick-o-meter: ${sanitizedRating}/${scale}`}</Typography>
		</Stack>
	);
};

export default PatrickOMeter;
